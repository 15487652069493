/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { getRequestOptions } from '../components/GetToken'
import { KTSVG } from '../../_metronic/helpers'
import { Tab, Tabs } from 'react-bootstrap-v5'

function WorkCenterDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState({});

    useEffect(() => {
        getWorkCenterDetails();
    }
        , [id]);

    const getWorkCenterDetails = () => {
        fetch(`${process.env.REACT_APP_API_URL}work_center_details/${id}`, getRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setDetails(resp.data)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    return (

        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Work Center Details
                    </span>
                </div>
            </div>
            <div className='card-body'>
                <div className='ml-12'>
                    <div className='row'>
                        <div className='col-6'>
                            <table className='table table-bordered table-hover'>
                                <tbody>
                                    <tr>
                                        <th>Work Center</th>
                                        <td>{details?.work_center_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Code</th>
                                        <td>{details?.work_center_code}</td>
                                    </tr>
                                    <tr>
                                        <th>Working Hours</th>
                                        <td>{details?.working_hours}</td>
                                    </tr>
                                    <tr>
                                        <th>Description</th>
                                        <td>{details?.remarks}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='form-group row ml-5'>
                        <Tabs defaultActiveKey='generalInformation' id='uncontrolled-tab-example' className='mb-3' >
                            <Tab eventKey='generalInformation' title='General Information'>
                                <div className="row">
                                    <div className="col-8">
                                        <table className='table table-bordered table-hover'>
                                            <tbody>
                                                <tr>
                                                    <th>Equipment Cost</th>
                                                    <td>{details?.equipment_cost}</td>
                                                </tr>
                                                <tr>
                                                    <th>Labor Cost</th>
                                                    <td>{details?.labor_cost}</td>
                                                </tr>
                                                <tr>
                                                    <th>Other Overhead Cost</th>
                                                    <td>{details?.other_overhead_cost}</td>
                                                </tr>
                                                <tr>
                                                    <th>Cost Per Hour</th>
                                                    <td>{details?.cost_per_hour}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Tab>
                            {details?.work_center_equipment?.length > 0 &&
                                <Tab eventKey='equipment' title='Equipment'>
                                    <div className="row">
                                        <div className="col-12">
                                            <table className='table table-bordered table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Equipment Name</th>
                                                        <th>Equipment Details</th>
                                                        <th>Cost Per Hour (TK)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {details?.work_center_equipment?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.equipment_name}</td>
                                                            <td>{item?.equipment_details}</td>
                                                            <td style={{ textAlign: "right" }}>{item?.equipment_cost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan="3" style={{ textAlign: "right" }}>Total</th>
                                                        <th style={{ textAlign: "right" }}>{details?.equipment_cost}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </Tab>
                            }
                            {details?.work_center_labor?.length > 0 &&
                                <Tab eventKey='labor' title='Labor'>
                                    <div className="row">
                                        <div className="col-12">
                                            <table className='table table-bordered table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Employee Name</th>
                                                        <th>Designation</th>
                                                        <th>Contact No</th>
                                                        <th>Cost Per Hour (TK)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {details?.work_center_labor?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.employee_name}</td>
                                                            <td>{item?.designation}</td>
                                                            <td>{item?.contact_no}</td>
                                                            <td style={{ textAlign: "right" }}>{item?.labor_cost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan="4" style={{ textAlign: "right" }}>Total</th>
                                                        <th style={{ textAlign: "right" }}>{details?.labor_cost}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </Tab>
                            }
                            {details?.work_center_overhead?.length > 0 &&
                                <Tab eventKey='others' title='Others'>
                                    <div className="row">
                                        <div className="col-12">
                                            <table className='table table-bordered table-hover'>
                                                <thead>
                                                    <tr>
                                                        <th>SN</th>
                                                        <th>Overhead Cost Name</th>
                                                        <th>Cost Per Hour (TK)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {details?.work_center_overhead?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item?.overhead_cost_name}</td>
                                                            <td style={{ textAlign: "right" }}>{item?.overhead_cost}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th colSpan="2" style={{ textAlign: "right" }}>Total</th>
                                                        <th style={{ textAlign: "right" }}>{details?.other_overhead_cost}</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </Tab>
                            }
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default WorkCenterDetails;