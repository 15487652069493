import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'

const WipQcDetails = (props) => {
	const [details, setDetails] = useState('')

	useEffect(() => {
		getQcDetails(props.match.params.id)
	}, [])

	const getQcDetails = (qcId) => {
		fetch(`${process.env.REACT_APP_API_URL}wip_qc_details/${qcId}`, getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setDetails(resp)
			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}



	return (
		<div className='card card-custom'>
			<div className='card-header'>
				<div className='card-title'>
					<KTSVG
						path='/media/icons/duotune/text/txt001.svg'
						className='svg-icon-1 svg-icon-primary'
					/>
					<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
						WIP QC Details
					</span>
				</div>
			</div>
			<div className='card-body'>
				<div className='ml-12'>
					<div className='row'>
						<div className='col-6'>
							<table className='table table-bordered table-hover'>
								<tbody>
									<tr>
										<th>QC Date</th>
										<td>{details.qcDate !== undefined && details.qcDate}</td>
									</tr>
									<tr>
										<th>Order No</th>
										<td>{details.order?.orderNo}</td>
									</tr>
									<tr>
										<th>Production Item</th>
										<td>{details.order?.product?.itemName}</td>
									</tr>
									<tr>
										<th>Operation</th>
										<td>{details.operation?.operationName}</td>
									</tr>
									<tr>
										<th>QC Qty</th>
										<td>{details.qcQty !== undefined && details.qcQty}</td>
									</tr>
									<tr>
										<th>QC Passed Qty</th>
										<td>{details.qcPassedQty !== undefined && details.qcPassedQty}</td>
									</tr>
									<tr>
										<th>QC Damage Qty</th>
										<td>{details.qcDamageQty !== undefined && details.qcDamageQty}</td>
									</tr>
									<tr>
										<th>Remarks</th>
										<td>{details.remarks !== undefined && details.remarks}</td>
									</tr>


								</tbody>
							</table>
						</div>
						<div className="col-6">
							<div className='card card-custom'>
								<div className='card-header'>
									<div className='card-title'>
										<h3 className='card-label'>	Wip  QC History</h3>
									</div>
								</div>
								<div className='card-body'>
									<div className='timeline timeline-3' style={{ height: '250px', overflow: 'auto' }}>
										<div className='timeline-items'>
											{details?.status_history?.map((item, index) => (
												<div className='timeline-item' key={index}>
													<div className='timeline-media'>
														{item.status === 'created' && <i className='fa fa-plus text-primary'></i>}
													</div>
													<div className='timeline-content'>
														<div className='d-flex align-items-center justify-content-between mb-3'>
															<div className='mr-4'>
																<span
																	className='mr-3 font-weight-bolder'
																	style={{ fontSize: '15px', color: '#e35b5a' }}
																>
																	Work-in-progress QC has been
																	{item.status === 'created' && ' Created'}
																	<span className='ml-2' style={{ color: 'black' }}> at {item.create_date}</span>
																</span>
																{item.status === 'created' && (
																	<>
																		<p className='mr-3'>
																			Created By :{' '}
																			<a href='/#'>
																				<b>{item.fullName}</b>
																			</a>
																		</p>
																	</>
																)}
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}

export default WipQcDetails
