import React, {useState,useEffect} from 'react';
import {Spinner } from "react-bootstrap-v5";
import {KTSVG} from '../../_metronic/helpers';

function ExpenseDt(props) {

    const tableHead = [
        { 'value': 'id', 'title': 'Id' },
        { 'value': 'expenseType', 'title': 'Expense Type' },
        { 'value': 'action', 'title': 'Action' }
    ];
    

    const url = process.env.REACT_APP_API_URL + "expense_type_dt";
    const [loading,setLoading] = useState(true);
    const [entities, setEntities] = useState({
            data: [],
            current_page: 1,
            from: 1,
            last_page: 1,
            per_page: 5,
            to: 1,
            total: 1,
    });
    
    const [first_page] = useState(1);
    const [current_page, setCurrentPage] = useState(1);
    const [sorted_column, setSortedColumn] = useState(tableHead[0].value);
    const [offset] = useState(4);
    const [order, setOrder] = useState('DESC');
    const [limit] = useState('5');
    const [orderBy] = useState('Id');
    const [orderType] = useState('ASC');
    const [globalSearch, setGlobalSearch] = useState('');
    const [columns, setColumns] = useState({

        'id': {
            filterType: 'like',
            filterValue: ''
        },
        'expenseType': {
            filterType: 'like',
            filterValue: ''
        }
    }); 


    useEffect(() => {

        const fetchEntities = () => {
            setLoading(true);
            let fetchUrl = `${url}?page=${current_page}&column=${sorted_column}&order=${order}&globalSearch=${globalSearch}&per_page=${entities.per_page}`;
    
            const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
            fetch(fetchUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt().token },
                body:JSON.stringify({entities:entities,firstPage:first_page, current_page:current_page,sorted_column:sorted_column,offset:offset,order:order,limit:limit,orderBy:orderBy,orderType:orderType, globalSearch:globalSearch, columns:columns})
            })
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                setEntities(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error, "catch the hoop")
            });
        }

        fetchEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current_page,sorted_column,order,globalSearch,columns,entities.per_page,props.addNewExpenseType,props.onCreate]);

    const changePage = pageNumber => {
        setCurrentPage(pageNumber);
	}

	const changePerPage = evt => {
        setEntities({ ...entities, per_page:evt.target.value});
        setCurrentPage(1);
	}

	const changeGlobalSearch = evt => {
        setGlobalSearch(evt.target.value);
        setCurrentPage(1);
	}
    
	const columnsFilterChange = evt => {
        setColumns({ ...columns,[evt.target.name]: {
            filterType: 'like',
            filterValue: evt.target.value
        },});
        setCurrentPage(1);
	}

	const columnHead = (value) =>{
		return value.split('_').join(' ');
	}

    const pagesNumbers = () => {
		if (!entities.to) {
			return [];
		}
		let from = entities.current_page - offset;
		if (from < 1) {
			from = 1;
		}
		let to = from + (offset * 2);
		if (to >= entities.last_page) {
			to = entities.last_page;
		}
		let pagesArray = [];

		for (let page = from; page <= to; page++) {
			pagesArray.push(page);
		}
		return pagesArray;
	}

    const tableHeads = () =>{
		let icon;
		if (order === 'asc') {
			icon = <i className="fa fa-arrow-up"></i>;
		} else {
			icon = <i className="fa fa-arrow-down"></i>;
		}
		return tableHead.map((column, index) => {
			if (column.value === 'action') {
				return <th style={{ "textAlign": "center" }} key={index}>
					<p className="text-primary"> {column.title}</p>
				</th>
			}
			else {
				return <th style={{ "textAlign": "center" }} key={index} onClick={() => sortByColumn(column.value)}>
					<p className="text-primary"> {columnHead(column.title)}{column.value === sorted_column && <span style={{float:"right"}}>{icon}</span>}</p>
				</th>
			}

		});
	}

	const tableHeadFilter = () =>{

		return tableHead.map((column, index) => {
			if (column.value === 'action') {
				return <td key={index}></td>
			}
			else {
				return <td key={index} >
					<input type="text" name={column.value} value={columns[column.value].filterValue} onChange={columnsFilterChange} className="form-control form-control-sm" style={{ "borderColor": "#e4e6ef" }} />
				</td>
			}
		});

	}

    const dataList = () =>{
		if (entities.data.length) {
			return entities.data.map((value, key) => {
				return <tr key={key} >
					<td className="text-center">{value.id}</td>
                    <td className="text-center">{value.expenseType}</td>
                    <td style={{ "textAlign": "center" }}>
                        <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={() => props.onAction(value.id, value.expenseType)}>
                        <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-1 svg-icon-3'
                            />
                        </button>
                    </td>
				</tr>
			})
		} else {
			return <tr>
				<td colSpan={tableHead.length} className="text-center">No Records Found.</td>
			</tr>
		}
	}

	const sortByColumn = (column) =>{
		if (column === sorted_column) {
            if(order === 'asc'){
                setOrder('desc');
                setCurrentPage(first_page);
            }
            else{
                setOrder('asc');
            }
        } else {
            setSortedColumn(column);
            setOrder('asc');
            setCurrentPage(first_page);
		}
	}

	const pageList = () =>{
		return pagesNumbers().map(page => {
			return <li className={page === entities.current_page ? 'page-item active' : 'page-item'} key={page}>
				<button className="page-link" onClick={() => changePage(page)}>{page}</button>
			</li>
		})
	}

    return (

        <> 
            <div className="row">
                <div className="col-md-2">
                    <select className="form-select form-select-sm" value={entities.per_page} onChange={changePerPage} style={{ "borderColor": "#e4e6ef" , "textAlign":"center"}}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                    </select>
                </div>
                <div className="pull-right col-md-6">
                    <input type="text" placeholder="Global Search" value={globalSearch} onChange={changeGlobalSearch} className="form-control form-control-sm" style={{ "borderColor": "#e4e6ef" }} />
                </div>
            </div>
            <br />
            <div className="table-responsive" style={{ 'height': '550px', 'overflow': 'auto' }}>
                <table className="table table-row-bordered">
                    <thead>
                        <tr className='fw-bolder text-muted bg-light'>{tableHeads()}</tr>
                        <tr>
                            {
                                tableHeadFilter()
                            }
                        </tr>
                    </thead>
                    <tbody>{loading ? <tr><td colSpan={tableHead.length} className="text-center"> <Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /></td></tr> : dataList()}</tbody>
                </table>
            </div>
            {(entities.data && entities.data.length > 0) &&
                <>
                    <div className="pt-2"></div>
                    <nav>
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" disabled={1 === entities.current_page} onClick={() => changePage(entities.current_page - 1)} >
                                    Previous
                                </button>
                            </li>
                            {pageList()}
                            <li className="page-item">
                                <button className="page-link" disabled={entities.last_page === entities.current_page} onClick={() => changePage(entities.current_page + 1)}>
                                    Next
                                </button>
                            </li>
                            <span style={{ marginTop: '8px' }}> &nbsp; <i>Displaying {entities.data.length} of {entities.total} entries.</i></span>
                        </ul>
                    </nav>
                </>
            }
        </>

    )
}
export default ExpenseDt