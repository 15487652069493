import React, {useState, useContext, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AsyncSelect from 'react-select/async'
import {getRequestOptions} from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import {AppContext} from '../context/AppContext'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';

function ProductionItemMovementReport (props) {
  const [date, setDate] = useState('')
  const [buName, setBuName] = useState('')
  const [reportData, setReportData] = useState([])
  const [loading, setLoading] = useState(false)

  const {bu} = useContext(AppContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + 'get_business_units_details/' + bu, getRequestOptions())
      const json = await response.json()
      setBuName(json.data.projectName)
    }
    if(bu !==""){
    fetchData()
    }
  }, [bu])

  const handleSubmit = (evt) => {
    evt.preventDefault()

    getReportData()
  }

  const getReportData = () => {
    setLoading(true)

    const jwt = () => {
      return JSON.parse(localStorage.getItem('MyToken'))
    }
    const formData = {
      date: date,
      buId: bu,
    }

    const requestOptions = postRequestOptions(formData);

    var apiEnd = 'report/production_item_movement'

    fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        console.log(resp)
        setLoading(false)
        if (resp.success === true) {
          setReportData(resp.data)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            html: resp.errorMessage,
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error, 'catch the hoop')
      })
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            Production Items Movements
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='ml-12'>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <label
                  htmlFor='date'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Date <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <input
                    type='date'
                    name='date'
                    id='date'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className='form-control form-control-sm'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-lg-3'></div>
                <div className='col-lg-5'>
                  <button type='submit' className='btn btn-sm btn-success mb-2'>
                    Ok
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className='col-12'>
            <h4 style={{'textAlign':'center'}}>{buName}</h4>
            <h4 style={{'textAlign':'center'}}>Daily Production Items</h4>
            <p style={{'textAlign':'center'}}>As on Date { date!==""?moment(date).format("D-MMM-YYYY"):''}</p>
          </div>

          <div className='col-12'>
            <div className='table-responsive'>
              <table
                className='table table-bordered table-hover'
                style={{minWidth: 'max-content', marginBottom: '150px'}}
              >
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Item Name</th>
                    <th>Item Code</th>
                    <th>Cutting-WIP</th>
                    <th>Cutting-QC</th>
                    <th>Hardening & Tempering-WIP</th>
                    <th>Hardening & Tempering-QC</th>
                    <th>Shot Peening-WIP</th>
                    <th>Shot Peening-QC</th>
                    <th>Painting-WIP</th>
                    <th>Paiting-QC</th>
                  </tr>
                </thead>
                <tbody>
                  
                    {loading && <tr>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                      <td> <p><Skeleton count={14} /></p></td>
                    </tr>
                    }
                    {!loading && reportData.map((item, index) => 
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.itemName}</td>
                        <td>{item.itemCode}</td>
                        <td>{item.wipCutting}</td>
                        <td>{item.cuttingQc}</td>
                        <td>{item.wipHardeningTempering}</td>
                        <td>{item.hardeningTemperingQc}</td>
                        <td>{item.wipShotPeening}</td>
                        <td>{item.shotPeeningQc}</td>
                        <td>{item.wipPainting}</td>
                        <td>{item.paintingQc}</td>
                      </tr>
                    
                    )}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default ProductionItemMovementReport
