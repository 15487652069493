import React from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'


function ProductionOrderList() {

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{ value: 'productionOrderNo', title: 'Order No', style: { textAlign: 'center' } },
		{ value: 'scheduledDate', title: 'Scheduled Date', style: { textAlign: 'center' } },
		{ value: 'product', title: 'product', style: { textAlign: 'center' } },
		{ value: 'quantity', title: 'Quantity', style: { textAlign: 'center' } },
		{ value: 'status', title: 'Status', style: { textAlign: 'center' } },
		{
			value: 'action', title: 'Action', actions: [
				{ link: 'production-order-details', calAction: false },
			],
			style: { textAlign: 'center' }
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		productionOrderNo: {
			filterType: 'like',
			filterValue: '',
		},
		scheduledDate: {
			filterType: 'like',
			filterValue: '',
		},
		product: {
			filterType: 'like',
			filterValue: '',
		},
		quantity: {
			filterType: 'like',
			filterValue: '',
		},
		status: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'production_order_dt'


	return (
		<>
			<div className='card card-custom'>
				<div className='card-header'>
					<div className='card-title'>
						<KTSVG
							path='/media/icons/duotune/text/txt001.svg'
							className='svg-icon-1 svg-icon-primary'
						/>
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							Production Order List
						</span>
					</div>
				</div>
				<div className='card-body'>
					<DataTable url={url} columns={columns} filters={filters} >
						<span className='svg-icon svg-icon-md svg-icon-primary'>
							<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
						</span>
					</DataTable>
				</div>
			</div>
		</>
	)
}

export default ProductionOrderList
