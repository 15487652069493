/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { KTSVG } from '../../_metronic/helpers'
import DataTable from '../components/DataTable'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg';

function WorkCenter() {
	const [workCenterInfo, setWorkCenterInfo] = useState({
		workCenterName: '',
		workCenterCode: '',
		workingHours: '',
		description: '',
		costInformation: 'summarized',
		generalInfo: {
			equipmentCost: 0,
			laborCost: 0,
			otherOverheadCost: 0,
			costPerHour: 0,
		},
		equipment: [{
			equipmentName: '',
			equipmentDetails: '',
			costPerHour: 0
		}],
		labor: [{
			employeeName: '',
			designation: '',
			contactNo: '',
			costPerHour: 0
		}],
		otherOverheadCost: [{
			overheadCostName: '',
			costPerHour: 0
		}]
	})

	const [activeTab, setActiveTab] = useState('generalInformation');
	const [newData, setNewData] = useState('')


	const url = process.env.REACT_APP_API_URL + 'work_center/list'

	useEffect(() => {
		generalInfoCalculate();
	}, [workCenterInfo.generalInfo.equipmentCost, workCenterInfo.generalInfo.laborCost, workCenterInfo.generalInfo.otherOverheadCost])

	useEffect(() => {
		calculation();
	}, [workCenterInfo.equipment, workCenterInfo.labor, workCenterInfo.otherOverheadCost, workCenterInfo.costInformation])

	const handleChange = (e) => {
		setWorkCenterInfo({ ...workCenterInfo, [e.target.name]: e.target.value })
	}

	const handleSelectChange = (e) => {
		setWorkCenterInfo({ ...workCenterInfo, workingHours: e.value })
	}

	const generalInfoCalculate = () => {
		setWorkCenterInfo({ ...workCenterInfo, generalInfo: { ...workCenterInfo.generalInfo, costPerHour: Number(workCenterInfo.generalInfo.equipmentCost) + Number(workCenterInfo.generalInfo.laborCost) + Number(workCenterInfo.generalInfo.otherOverheadCost) } })
	}

	const handleCostInformationChange = () => {
		if (workCenterInfo.costInformation === 'details') {
			setWorkCenterInfo({ ...workCenterInfo, costInformation: 'summarized' })
			setActiveTab('generalInformation');
		} else {
			setWorkCenterInfo({ ...workCenterInfo, costInformation: 'details' })
		}
	}

	const handleEquipmentInfoChange = (index) => (e) => {
		const { name, value } = e.target;
		const list = [...workCenterInfo.equipment];
		list[index][name] = value;
		setWorkCenterInfo({ ...workCenterInfo, equipment: list });
	}

	const addMoreEquipment = () => {
		const newEquipment = [...workCenterInfo.equipment, { equipmentName: '', equipmentDetails: '', costPerHour: 0 }];
		setWorkCenterInfo({ ...workCenterInfo, equipment: newEquipment });
	};

	const removeEquipment = (index) => {
		const list = [...workCenterInfo.equipment];
		list.splice(index, 1);
		setWorkCenterInfo({ ...workCenterInfo, equipment: list });
	}

	const addMoreLabor = () => {
		const newLabor = [...workCenterInfo.labor, { employeeName: '', designation: '', contactNo: '', costPerHour: 0 }];
		setWorkCenterInfo({ ...workCenterInfo, labor: newLabor });
	}

	const removeLabor = (index) => {
		const list = [...workCenterInfo.labor];
		list.splice(index, 1);
		setWorkCenterInfo({ ...workCenterInfo, labor: list });
	}

	const addMoreOtherOverheadCost = () => {
		const newOtherOverheadCost = [...workCenterInfo.otherOverheadCost, { overheadCostName: '', costPerHour: 0 }];
		setWorkCenterInfo({ ...workCenterInfo, otherOverheadCost: newOtherOverheadCost });
	}

	const removeOtherOverheadCost = (index) => {
		const list = [...workCenterInfo.otherOverheadCost];
		list.splice(index, 1);
		setWorkCenterInfo({ ...workCenterInfo, otherOverheadCost: list });
	}

	const handleLaborInfoChange = (index) => (e) => {
		const { name, value } = e.target;
		const list = [...workCenterInfo.labor];
		list[index][name] = value;
		setWorkCenterInfo({ ...workCenterInfo, labor: list });
	}

	const handleOtherOverheadCostInfoChange = (index) => (e) => {
		const { name, value } = e.target;
		const list = [...workCenterInfo.otherOverheadCost];
		list[index][name] = value;
		setWorkCenterInfo({ ...workCenterInfo, otherOverheadCost: list });
	}

	const calculation = () => {
		const equipmentCost = workCenterInfo.equipment.reduce((a, b) => a + Number(b.costPerHour), 0)
		const laborCost = workCenterInfo.labor.reduce((a, b) => a + Number(b.costPerHour), 0)
		const otherOverheadCost = workCenterInfo.otherOverheadCost.reduce((a, b) => a + Number(b.costPerHour), 0)
		const costPerHour = equipmentCost + laborCost + otherOverheadCost
		setWorkCenterInfo({ ...workCenterInfo, generalInfo: { ...workCenterInfo.generalInfo, equipmentCost: equipmentCost, laborCost: laborCost, otherOverheadCost: otherOverheadCost, costPerHour: costPerHour } })
	}

	const handleSubmit = (evt) => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You wan't to create this work center!",
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: 'No',
			confirmButtonText: 'Yes',
		}).then((result) => {
			if (result.value) {
				const formData = { ...workCenterInfo }
				const requestOptions = postRequestOptions(formData)

				fetch(process.env.REACT_APP_API_URL + 'work_center/create', requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						if (resp.success === true) {
							const successMsg = resp.successMessage
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							setNewData({
								id: resp.data.id,
								name: resp.data.name
							});
						} else {
							const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}
	const workingHoursOptions = [
		{ value: 8, label: 'Standard 8 Hours/Day' },
		{ value: 16, label: 'Standard 16 Hours/Day' },
		{ value: 40, label: 'Standard 40 Hours/Week' },
		{ value: 48, label: 'Standard 48 Hours/Week' },
	]

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{ value: 'workCenterName', title: 'Work Center Name', style: { textAlign: 'left' } },
		{ value: 'workCenterCode', title: 'Work Center Code', style: { textAlign: 'left' } },
		{ value: 'workingHours', title: 'Working Hours', style: { textAlign: 'center' } },
		{ value: 'costPerHour', title: 'Cost Per Hour', style: { textAlign: 'right' } },
		{ value: 'description', title: 'Description', style: { textAlign: 'left' } },
		{
			value: 'action', title: 'Action', actions: [
				{ link: 'setup-work-center-details', calAction: false }
			],
			style: { textAlign: 'center' }
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		workCenterName: {
			filterType: 'like',
			filterValue: '',
		},
		workCenterCode: {
			filterType: 'like',
			filterValue: '',
		},
		workingHours: {
			filterType: 'like',
			filterValue: '',
		},
		costPerHour: {
			filterType: 'like',
			filterValue: '',
		},
		description: {
			filterType: 'like',
			filterValue: '',
		},
	}

	const onAction = (data) => {
		console.log(data)
	}

	return (
		<>
			<div className='card card-custom'>
				<div className='card-header'>
					<div className='card-title'>
						<KTSVG
							path='/media/icons/duotune/ecommerce/ecm006.svg'
							className='svg-icon-1 svg-icon-primary'
						/>
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							Create Work Center
						</span>
					</div>
				</div>

				<div className='ml-12'>
					<form>
						<div className='card-body'>
							<div className='form-group row'>
								<label
									htmlFor='workCenter'
									className='col-lg-3 col-form-label'
									style={{ textAlign: 'right' }}
								>
									Work Center <span className='required text-danger'></span>
								</label>
								<div className='col-lg-6'>
									<input
										type='text'
										className='form-control form-control-sm'
										id='workCenterName'
										name='workCenterName'
										placeholder='Work Center'
										value={workCenterInfo.workCenterName}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className='form-group row'>
								<label
									htmlFor='workCenterCode'
									className='col-lg-3 col-form-label'
									style={{ textAlign: 'right' }}
								>
									Code <span className='required text-danger'></span>
								</label>
								<div className='col-lg-6'>
									<input
										type='text'
										className='form-control form-control-sm'
										id='workCenterCode'
										name='workCenterCode'
										placeholder='Code'
										value={workCenterInfo.workCenterCode}
										onChange={handleChange}
									/>
								</div>
							</div>

							<div className='form-group row'>
								<label
									htmlFor='workingHours'
									className='col-lg-3 col-form-label'
									style={{ textAlign: 'right' }}
								>
									Working Hours <span className='required text-danger'></span>
								</label>
								<div className='col-lg-6'>
									<Select options={workingHoursOptions} onChange={handleSelectChange} />
								</div>
							</div>
							<div className='form-group row'>
								<label
									htmlFor='description'
									className='col-lg-3 col-form-label'
									style={{ textAlign: 'right' }}
								>
									Description
								</label>
								<div className='col-lg-6'>
									<textarea
										className='form-control form-control-sm'
										id='description'
										name='description'
										placeholder='Description'
										value={workCenterInfo.description}
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className='form-group row mt-2'>
								<label
									htmlFor='costInformation'
									className='col-lg-3 col-form-label'
									style={{ textAlign: 'right' }}
								>
									Cost Information
								</label>
								<div className='col-lg-6'>
									<div className='form-check form-check-custom form-check-solid'>
										<span >
											<input className='form-check-input' type='checkbox' value={workCenterInfo.costInformation} onChange={handleCostInformationChange} checked={workCenterInfo.costInformation === 'summarized'} />
										</span>
										<label htmlFor="summarized" className="col-form-label" style={{ textAlign: "left", marginLeft: "5px" }} > Summarized</label>
										<span style={{ marginLeft: "5px" }}>
											<input className='form-check-input' type='checkbox' value={workCenterInfo.costInformation} onChange={handleCostInformationChange} checked={workCenterInfo.costInformation === 'details'} />
										</span>
										<label htmlFor="details" className="col-form-label" style={{ marginLeft: "10px" }} > Details</label>
									</div>
								</div>
							</div>

							<div className='form-group row mt-5'>
								<ul className="nav nav-tabs nav-line-tabs m-5 fs-6">
									<li className="nav-item" onClick={() => setActiveTab('generalInformation')}>
										<a className={`nav-link ${activeTab === 'generalInformation' ? 'active' : ''}`} data-bs-toggle="tab" >
											General Information
										</a>
									</li>
									{workCenterInfo.costInformation !== 'summarized' && <>
										<li className="nav-item" onClick={() => setActiveTab('equipment')}>
											<a className={`nav-link ${activeTab === 'equipment' ? 'active' : ''}`} data-bs-toggle="tab">
												Equipment
											</a>
										</li>
										<li className="nav-item" onClick={() => setActiveTab('labor')}>
											<a className={`nav-link ${activeTab === 'labor' ? 'active' : ''}`} data-bs-toggle="tab">
												Labor
											</a>
										</li>
										<li className="nav-item" onClick={() => setActiveTab('others')}>
											<a className={`nav-link ${activeTab === 'others' ? 'active' : ''}`} data-bs-toggle="tab">
												Others
											</a>
										</li>
									</>
									}
								</ul>
								<div className="tab-content" id="myTabContent">
									{activeTab === 'generalInformation' &&
										<>
											<div className='form-group row'>
												<label
													htmlFor='equipmentCost'
													className='col-lg-3 col-form-label'
													style={{ textAlign: 'right' }}
												>
													Equipment Cost
												</label>
												<div className='col-lg-6'>
													<input
														type='number'
														className='form-control form-control-sm'
														name='equipmentCost'
														placeholder='Equipment Cost'
														value={workCenterInfo.generalInfo.equipmentCost}
														onChange={e => setWorkCenterInfo({ ...workCenterInfo, generalInfo: { ...workCenterInfo.generalInfo, equipmentCost: e.target.value } })}
														readOnly={workCenterInfo.costInformation === 'details' ? true : false}
													/>
												</div>
											</div>
											<div className='form-group row'>
												<label
													htmlFor='laborCost'
													className='col-lg-3 col-form-label'
													style={{ textAlign: 'right' }}
												>
													Labor Cost
												</label>
												<div className='col-lg-6'>
													<input
														type='number'
														className='form-control form-control-sm'
														name='laborCost'
														placeholder='Labor Cost'
														value={workCenterInfo.generalInfo.laborCost}
														onChange={e => setWorkCenterInfo({ ...workCenterInfo, generalInfo: { ...workCenterInfo.generalInfo, laborCost: e.target.value } })}
														readOnly={workCenterInfo.costInformation === 'details' ? true : false}

													/>
												</div>
											</div>
											<div className='form-group row'>
												<label
													htmlFor='otherOverheadCost'
													className='col-lg-3 col-form-label'
													style={{ textAlign: 'right' }}
												>
													Other Overhead Cost
												</label>
												<div className='col-lg-6'>
													<input
														type='number'
														className='form-control form-control-sm'
														name='otherOverheadCost'
														placeholder='Other Overhead Cost'
														value={workCenterInfo.generalInfo.otherOverheadCost}
														onChange={e => setWorkCenterInfo({ ...workCenterInfo, generalInfo: { ...workCenterInfo.generalInfo, otherOverheadCost: e.target.value } })}
														readOnly={workCenterInfo.costInformation === 'details' ? true : false}
													/>
												</div>
											</div>
											<div className='form-group row'>
												<label
													htmlFor='costPerHour'
													className='col-lg-3 col-form-label'
													style={{ textAlign: 'right' }}
												>
													Cost Per Hour
												</label>
												<div className='col-lg-6'>
													<input
														type='number'
														className='form-control form-control-sm'
														name='costPerHour'
														placeholder='Cost Per Hour'
														value={workCenterInfo.generalInfo.costPerHour}
														readOnly
													/>
												</div>
											</div>
										</>
									}
									{activeTab === 'equipment' &&
										<>
											<div className="table-responsive m-5">
												<table className="table table-bordered table-hover">
													<thead>
														<tr>
															<th>SN</th>
															<th>Equipment Name</th>
															<th>Equipment Details</th>
															<th>Cost Per Hour(TK)</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{workCenterInfo.equipment.map((equip, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td>
																	<input type='text' name='equipmentName' className='form-control form-control-sm' onChange={handleEquipmentInfoChange(index)} value={equip.equipmentName} />
																</td>
																<td>
																	<input type='text' name='equipmentDetails' className='form-control form-control-sm' onChange={handleEquipmentInfoChange(index)} value={equip.equipmentDetails} />
																</td>
																<td>
																	<input type='number' name='costPerHour' className='form-control form-control-sm' onChange={handleEquipmentInfoChange(index)} value={equip.costPerHour} />
																</td>
																<td>
																	{index === (workCenterInfo.equipment.length - 1) &&
																		<button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addMoreEquipment}>
																			<KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
																		</button>
																	}
																	{workCenterInfo.equipment.length > 1 &&
																		<button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={() => removeEquipment(index)}>
																			<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
																		</button>
																	}
																</td>
															</tr>
														))}
													</tbody>
													<tfoot>
														<tr>
															<td colSpan="3" style={{ textAlign: "right" }}>Total</td>
															<td style={{ textAlign: "right" }}>{workCenterInfo.generalInfo.equipmentCost}</td>
															<td></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</>
									}
									{activeTab === 'labor' &&
										<>
											<div className="table-responsive m-5">
												<table className="table table-bordered table-hover">
													<thead>
														<tr>
															<th>SN</th>
															<th>Employee Name</th>
															<th>Designation</th>
															<th>Contact No</th>
															<th>Cost Per Hour(TK)</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{workCenterInfo.labor.map((lb, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td>
																	<input type='text' name='employeeName' className='form-control form-control-sm' onChange={handleLaborInfoChange(index)} value={lb.employeeName} />
																</td>
																<td>
																	<input type='text' name='designation' className='form-control form-control-sm' onChange={handleLaborInfoChange(index)} value={lb.designation} />
																</td>
																<td>
																	<input type='text' name='contactNo' className='form-control form-control-sm' onChange={handleLaborInfoChange(index)} value={lb.contactNo} />
																</td>
																<td>
																	<input type='number' name='costPerHour' className='form-control form-control-sm' onChange={handleLaborInfoChange(index)} value={lb.costPerHour} />
																</td>
																<td>
																	{index === (workCenterInfo.labor.length - 1) &&
																		<button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addMoreLabor}>
																			<KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
																		</button>
																	}
																	{workCenterInfo.labor.length > 1 &&
																		<button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={() => removeLabor(index)}>
																			<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
																		</button>
																	}
																</td>
															</tr>
														))}
													</tbody>
													<tfoot>
														<tr>
															<td colSpan="4" style={{ textAlign: "right" }}>Total</td>
															<td style={{ textAlign: "right" }}>{workCenterInfo.generalInfo.laborCost}</td>
															<td></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</>
									}
									{activeTab === 'others' &&
										<>
											<div className="table-responsive m-5">
												<table className="table table-bordered table-hover">
													<thead>
														<tr>
															<th>SN</th>
															<th>Overhead Cost Name</th>
															<th>Cost Per Hour(TK)</th>
															<th>Action</th>
														</tr>
													</thead>
													<tbody>
														{workCenterInfo.otherOverheadCost.map((overHead, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td>
																	<input type='text' name='overheadCostName' className='form-control form-control-sm' onChange={handleOtherOverheadCostInfoChange(index)} value={overHead.overheadCostName} />
																</td>
																<td>
																	<input type='number' name='costPerHour' className='form-control form-control-sm' onChange={handleOtherOverheadCostInfoChange(index)} value={overHead.costPerHour} />
																</td>
																<td>
																	{index === (workCenterInfo.otherOverheadCost.length - 1) &&
																		<button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addMoreOtherOverheadCost}>
																			<KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
																		</button>
																	}
																	{workCenterInfo.otherOverheadCost.length > 1 &&
																		<button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={() => removeOtherOverheadCost(index)}>
																			<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
																		</button>
																	}
																</td>
															</tr>
														))}
													</tbody>
													<tfoot>
														<tr>
															<td colSpan="2" style={{ textAlign: "right" }}>Total</td>
															<td style={{ textAlign: "right" }}>{workCenterInfo.generalInfo.otherOverheadCost}</td>
															<td></td>
														</tr>
													</tfoot>
												</table>
											</div>
										</>

									}
								</div>
							</div>

							<div className='row'>
								<div className='col-3'></div>
								<div className='col-6'>
									<input type='submit' className='btn btn-sm btn-success mt-2' onClick={handleSubmit} />
								</div>
							</div>
							<br />
						</div>
					</form>
					<DataTable
						url={url}
						columns={columns}
						filters={filters}
						newData={newData}
						onAction={onAction}
					>
						<span className='svg-icon svg-icon-md svg-icon-primary'>
							<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
						</span>
					</DataTable>
				</div>
			</div>
		</>
	)
}

export default WorkCenter
