/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationMessage from '../helpers/ValidationErrorMsg'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tab, Tabs } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import usePagePermission from "../components/usePagePermission";
import Error500 from '../components/Error500';
import { minWidth } from '@mui/system'

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};


const BomCreate = () => {

	const [product, setProduct] = useState('');
	const [copyproduct, setCopyProduct] = useState('');
	const [productCode, setProductCode] = useState('');
	const [unit, setUnit] = useState('');
	const [quantity, setQuantity] = useState('');
	const [bomType, setBomType] = useState('production');
	const [bomComponents, setBomComponents] = useState([{ item: "", itemCode: "", unit: "", quantity: "" }]);
	const [bomOperations, setBomOperations] = useState([{ operation: "", step: "", workCenter: "", duration: "" }]);
	const [bomItems, setBomItems] = useState([
		{
			operation: "",
			workCenter: "",
			duration: "",
			hasRawMaterial: true,
			hasQc: true,
			qcTolerance: null,
			operationItems: [{ item: "", itemCode: "", unit: "", quantity: "" }]
		}
	])
	// useEffect(() => {

	//     getProductOperations();

	// }, [])

	const hasPermission = usePagePermission('/bom-create');
	//console.log(hasPermission)

	const getProductOperations = (productId) => {
		fetch(process.env.REACT_APP_API_URL + 'product_operations/' + productId, getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const newItems = resp.map((row) => {
					row.operation = { label: row.operationName, value: row.operationId };
					row.step = row.sequence;
					row.duration = row.defaultDuration;
					row.workCenter = { label: row.work_center.workCenterName, value: row.work_center.id_work_center }
					return row;
				});
				if (newItems.length > 0) {
					setBomOperations(newItems);
				}
				else {
					setBomOperations([{ operation: "", step: "", workCenter: "", duration: "" }]);
				}

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const getProduct = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "item/manufacturing_item_search";
		} else {
			api = process.env.REACT_APP_API_URL + "item/manufacturing_item_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.itemId, label: `${element.itemName} ${element.itemCode}`, value: element.itemId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}

	const searchProductOperation = (inputValue, callback) => {
		let productId = product.itemId !== undefined ? product.itemId : null;
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "product_operation_search/" + productId;
		} else {
			api = process.env.REACT_APP_API_URL + "product_operation_search/" + productId + "?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.operationId, label: `${element.operationName}`, value: element.operationId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}


	const getWorkCenter = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "work_center_search";
		} else {
			api = process.env.REACT_APP_API_URL + "work_center_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.workCenterId, label: `${element.workCenterName} ${element.workCenterCode}`, value: element.workCenterId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}


	const handleProductChange = (product) => {
		setProduct(product);
		setProductCode(product.itemCode);
		setUnit(product.unitName);
		getProductOperations(product.value);
	}

	const bomTypeArr = [{ label: 'Production', value: 'production' }, { label: 'Assembly ', value: 'assembly' }]


	const fetchItemData = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "item/item_search";
		} else {
			api = process.env.REACT_APP_API_URL + "item/item_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.itemId, label: `${element.itemName} ${element.itemCode}`, value: element.itemId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}



	const onOperationItemChange = (index, i) => item => {

		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.operationItems.map((opi, ri) => {
					if (i === ri) {
						opi.item = item;
						opi.unit = item.unitName;
						opi.itemCode = item.itemCode;

					}
					return opi
				})

			}
			return row
		})

		setBomItems(newItems)

	}



	const onComponentsQtyChange = (index, i) => evt => {
		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.operationItems.map((opi, ri) => {
					if (i === ri) {
						opi.quantity = evt.target.value;
					}
					return opi
				})
			}
			return row;
		})

		setBomItems(newItems);

	}

	const onBomOperationChange = (index) => operation => {

		const newItems = bomItems.map(function (row, rindex) {
			if (index !== rindex) return row;
			return { ...row, operation: operation }
		});
		setBomItems(newItems);
	}


	const onBomWorkCenterChange = (index) => workCenter => {

		console.log(workCenter)
		const newItems = bomItems.map(function (row, rindex) {
			if (index !== rindex) return row;
			return { ...row, workCenter: workCenter }
		});
		setBomItems(newItems);
	}



	const addBomOperationItem = (index) => () => {

		const newItems = bomItems.map(function (row, rindex) {

			if (index === rindex) {

				row.operationItems = row.operationItems.concat([{ item: "", itemCode: "", unit: "", quantity: "" }]);

				console.log(row.operationItems)

			}
			return row
		})

		setBomItems(newItems);

	}

	const removeBomOperationItem = (index, itemIndex) => () => {
		//console.log(index,itemIndex)
		if (bomItems[index].operationItems.length === 1) {
			const newItems = bomItems.filter((row, rindex) => index != rindex)
			//console.log(newItems);
			setBomItems(newItems);
			return;
		}
		const newItems = bomItems.map((row, rindex) => {
			if (index === rindex) {
				row.operationItems = row.operationItems.filter((opi, ri) => itemIndex !== ri);
			}
			return row;
		})
		setBomItems(newItems);
	}



	const removeBomItem = (index) => () => {
		//console.log(index);
		const newItems = bomItems.filter((row, rindex) => index != rindex)
		//console.log(newItems);
		setBomItems(newItems);
	}


	const addBomItem = () => {
		setBomItems(
			bomItems.concat([
				{
					operation: "",
					workCenter: "",
					duration: "",
					hasRawMaterial: true,
					hasQc: false,
					qcTolerance: null,
					operationItems: [{ item: "", itemCode: "", unit: "", quantity: "" }],
				}
			])
		)
	}

	const handleCheck = (event) => {

		const newItems = bomItems.map((row, index) => {
			if (index == event.target.value) {
				row.hasRawMaterial = event.target.checked;
			}
			return row;
		}
		);
		setBomItems(newItems);

	};

	const handleQcCheck = (event) => {

		const newItems = bomItems.map((row, index) => {
			if (index == event.target.value) {
				row.hasQc = event.target.checked;
			}
			return row;
		}
		);
		setBomItems(newItems);
	}

	const onQcToleranceChange = (index) => evt => {
		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.qcTolerance = evt.target.value;
			}
			return row;
		});
		setBomItems(newItems);
	}

	const onDurationChange = (index) => evt => {
		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.duration = evt.target.value;
			}
			return row;
		});
		setBomItems(newItems);
	}

	
	const getProductBom = async (productId) => {

		const response = await fetch(process.env.REACT_APP_API_URL + 'bom_details_by_product_id/' + productId, getRequestOptions())

		if (response.ok) {
			const res = await response.json();
			const json = res.data;


			setBomItems([]);

			if (json !== null) {
				const bomItems = json.bomoperations.map((row, index) => {
					row.operation = {label: row.operation.operationName,value: row.operation.id_operations};        
					row.workCenter = { label: row.work_center !== null ? row.work_center.workCenterName : '', value: row.work_center !== null ? row.work_center.id_work_center : '' };
					row.hasRawMaterial = row.bomcomponents?.length === 0 ? false : true;
					row.operationItems = [{ item: "", itemCode: "", unit: "", quantity: "" }];
					row.hasQc = row.hasQc === 'yes' ? true : false;
					if (row.hasRawMaterial) {
						row.operationItems = row.bomcomponents.map((opi, i) => {
							opi.item = { ...opi.item, itemName: opi.item.itemName, itemId: opi.item.id_inv_items, itemCode: opi.item.itemCode, unitName: opi.item.unit, label: opi.item.itemName, value: opi.item.id_inv_items }
							opi.itemCode = opi.item.itemCode;
							opi.unit = opi.item.unit;
							opi.quantity = (Number(opi.quantity)).toFixed(2);
							return opi;
						})
					}

					return row;
				})

				setBomItems(bomItems);
				setQuantity(json.quantity);

			}
		}


	}

	const handleCopyProductChange = (product) => {
		setCopyProduct(product);
		getProductBom(product.value);
	}




	const handleSubmit = (evt) => {
		evt.preventDefault()

		var text = 'You want to add this BOM!'

		Swal.fire({
			title: 'Are you sure?',
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then((result) => {
			if (result.value) {
				const jwt = () => {
					return JSON.parse(localStorage.getItem('MyToken'))
				}
				const formData = {
					product: product,
					quantity: quantity,
					bomType: bomType,
					bomItems: bomItems
				}

				const requestOptions = postRequestOptions(formData);

				var apiEnd = 'bom_create'

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp)

						if (resp.success === true) {

							var successMsg = [`BOM ${resp.data.name} added`]


							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})

							setProduct('');
							setProductCode('');
							setUnit('');
							setQuantity('');
							setBomType('');
							setBomItems([{
								operation: "",
								workCenter: "",
								duration: "",
								hasRawMaterial: true,
								operationItems: [{ item: "", itemCode: "", unit: "", quantity: "" }]
							}
							]);
							setBomComponents([{ item: "", itemCode: "", unit: "", quantity: "" }]);
							setBomOperations([{ operation: "", step: "", workCenter: "", duration: "" }]);


						} else {
							const errorsMessage = validationMessage(resp.errors).join("<br>");
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	const tableBody = () => {
		const tbodies = bomItems.map((item, index) => {
			const operationItemsValues = Object.values(item.operationItems)
			const operationItemRows = operationItemsValues.map((operation, i) => {

				const slNo = i === 0 ? <td rowSpan={operationItemsValues.length}>{index + 1}</td> : null
				const operationCell =
					i === 0 ? (
						<td rowSpan={operationItemsValues.length}>

							<AsyncSelect
								key={index}
								value={item.operation}
								defaultOptions
								loadOptions={searchProductOperation}
								placeholder="Select Operation"
								onChange={onBomOperationChange(index)}
								styles={customStylesSelect}
							/>

						</td>
					) : null

				//const workCenter = i === 0 ? <td rowSpan={operationItemsValues.length}>{item.workCenter.label}</td> : null

				// work center async select const

				const workCenter = i === 0 ? <td rowSpan={operationItemsValues.length}>
					<AsyncSelect
						key={index}
						value={item.workCenter}
						defaultOptions
						loadOptions={getWorkCenter}
						placeholder="Select Work Center"
						onChange={onBomWorkCenterChange(index)}
						styles={customStylesSelect}
					/></td> : null


				const hasRawMaterial = i === 0 ? <td rowSpan={operationItemsValues.length}><input className='form-check-input' type='checkbox' value={index} key={index} onChange={handleCheck} checked={item.hasRawMaterial} /></td> : null

				const duration = i === 0 ? <td rowSpan={operationItemsValues.length}><input className='form-control form-control-sm' type='number' value={item.duration} onChange={onDurationChange(index)} /></td> : null

				const hasQc = i === 0 ? <td rowSpan={operationItemsValues.length}><input className='form-check-input' type='checkbox' value={index} key={index} onChange={handleQcCheck} checked={item.hasQc} /></td> : null

				const qcTolerance = i === 0 ? <td rowSpan={operationItemsValues.length}><input className='form-control form-control-sm' type='number' value={item.qcTolerance} onChange={onQcToleranceChange(index)} /></td> : null


				// const quantity =
				//   i === 0 ? (
				//     <td rowSpan={operationItemsValues.length}>
				//       <div className='col-sm-4'>
				//         <input
				//           className='form-control form-control-sm'
				//           style={{minWidth: '90px'}}
				//           readOnly={item.product === ''}
				//           type='number'
				//           value={item.quantity}
				//           onChange={onWipQtyChange(index)}
				//         />
				//       </div>
				//     </td>
				//   ) : null

				const action =
					i === 0 ? (
						<td rowSpan={operationItemsValues.length}>
							<div className='row'>
								<div className='col-2'>
									<button
										type='button'
										className='btn btn-icon btn-light btn-active-light-danger  btn-sm'
										onClick={removeBomItem(index)}
									>
										<span className='svg-icon svg-icon-md svg-icon-danger'>
											<KTSVG path={'/media/svg/icons/General/Trash.svg'} />
										</span>
									</button>
								</div>
							</div>
						</td>
					) : null

				return (
					<tr key={i}>
						{slNo}
						{operationCell}
						{workCenter}
						{hasRawMaterial}
						{item.hasRawMaterial && <> <td><AsyncSelect
							key={index}
							value={operation.item}
							defaultOptions
							loadOptions={fetchItemData}
							placeholder="Select Item"
							onChange={onOperationItemChange(index, i)}
							styles={customStylesSelect}
						/>
						</td>
							<td>{operation.item.itemCode}</td>
							<td>{operation.item.unitName}</td>
							<td>

								<div className='row'>
									<div className="col-sm-8"><input className="form-control form-control-sm " type="number" step="any" value={operation.quantity} onChange={onComponentsQtyChange(index, i)} /></div>
									<div className="col-sm-4">

										<button type='button' className='btn btn-light btn-active-light-danger  btn-sm' onClick={removeBomOperationItem(index, i)}>
											<i className="fa fa-trash"></i>
										</button>
										{i === (item.operationItems.length - 1) && <button type="button" className="btn btn-light btn-sm" onClick={addBomOperationItem(index)}><i className="fa fa-plus"></i></button>}
									</div>
								</div>

							</td> </>}
						{!item.hasRawMaterial && i == 0 && <td colSpan={4}></td>}
						{duration}
						{hasQc}
						{qcTolerance}
						{action}

					</tr>
				)
			})
			return operationItemRows
			//return <>{componentsRows}</>
		})

		return tbodies
	}


	return (
		<>
			{hasPermission == "1" ?
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<KTSVG
								path='/media/icons/duotune/text/txt001.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								BOM
							</span>
						</div>
					</div>
					<div className='card-body'>
						<div className='ml-12'>
							<form onSubmit={handleSubmit}>
								<div className='row'>
									<div className='col-6'>

										<div className='form-group row'>
											<label
												htmlFor='product'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Product <span className='required text-danger'></span>
											</label>
											<div className='col-md-8'>
												<AsyncSelect
													value={product}
													name="product"
													defaultOptions
													loadOptions={getProduct}
													placeholder="Select Product"
													onChange={handleProductChange}
													styles={customStylesSelect}
												/>

											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='productCode'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Product Code<span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<input
													type='text'
													name='productCode'
													id='productCode'
													value={productCode}
													onChange={(e) => setProductCode(e.target.value)}
													className="form-control form-control-sm" readOnly
												/>
											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='unit'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Unit<span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<input
													type='text'
													name='unit'
													id='unit'
													value={unit}
													onChange={(e) => setUnit(e.target.value)}
													className="form-control form-control-sm" readOnly
												/>
											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='duration'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Quantity <span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<input
													type="number"
													name="quantity"
													id="quantity"
													value={quantity}
													onChange={(e) => setQuantity(e.target.value)}
													className="form-control form-control-sm"
												/>
											</div>

										</div>


									</div>
									<div className='col-6'>


									<div className='form-group row'>
											<label
												htmlFor='copyproduct'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
										        Copy Form
											</label>
											<div className='col-md-4'>
												<AsyncSelect
													value={copyproduct}
													name="copyproduct"
													defaultOptions
													loadOptions={getProduct}
													placeholder="Select Product"
													onChange={handleCopyProductChange}
													styles={customStylesSelect}
												/>

											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='duration'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												BOM Type <span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<select className="form-select form-select-sm" id="bomType" name="bomType" value={bomType} onChange={(e) => setBomType(e.target.value)} >
													{bomTypeArr.map(function (item, id) {
														return <option key={id} value={item.value}>{item.label}</option>
													})
													}
												</select>

											</div>

										</div>

									</div>

									<div className="col-12">
										<Tabs defaultActiveKey="bom-components" >
											<Tab eventKey="bom-components" title="Components">
												<div className="table-responsive">
													<table className="table table-bordered table-hover" style={{ minWidth: "max-content", marginBottom: "150px" }}>
														<thead>
															<tr>
																<th>SL</th>
																<th>Route Stage</th>
																<th>Work Center</th>
																<th>Has raw materials</th>
																<th style={{ minWidth: '250px' }}>Item</th>
																<th>Code</th>
																<th>Unit</th>
																<th>Quantity</th>
																<th>Duration(Minutes)</th>
																<th>Has QC</th>
																<th>QC Tolerance(%)</th>
																<th>Action</th>
															</tr>
														</thead>
														<tbody>
															{tableBody()}
															<tr>
																<td colSpan="10">
																	<div className="form-group row">
																		<div className="col-lg-2">

																			<button type="button" className="btn btn-primary btn-sm" onClick={addBomItem}>Add More <i className="fa fa-plus"></i></button>

																		</div>
																	</div>
																</td>
															</tr>
														</tbody>

													</table>
												</div>
											</Tab>

										</Tabs>
									</div>

								</div>

								<div className='row'>
									<div className='col-4'></div>
									<div className='col-6' style={{ marginBottom: "15px" }}>
										<input
											type='submit'
											className='btn btn-sm btn-success mt-2'
											value="Save"
										/>
									</div>
								</div>
								{/* <pre>
        				  		{JSON.stringify(bomItems, null, 2)}
        					</pre> */}
							</form>
						</div>
						{/* <ToastContainer /> */}
					</div>
				</div>
				:
				<Error500 />

			}
		</>
	)
}
export default BomCreate;
