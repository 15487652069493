import React, {useState, useEffect, useRef} from 'react'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {KTSVG} from '../../_metronic/helpers'
import {getRequestOptions} from '../components/GetToken'


const ProductionDetails = (props) => {

  const [details,setDetails] = useState('');

  useEffect(()=>{
    getProductionDetails(props.match.params.id);
  },[])

  const getProductionDetails  = async(pId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}production_details/${pId}`, getRequestOptions());
      const data = await response.json();
      setDetails(data);
    } catch (error) {
      console.log(error, "caught the error");
    }
  }

  const tableBody = () => {
    
    const tbodies =  details.productionoperations?.map((item, index) => {
    const componentValues = Object.values(item.productioncomponents ?? {});

    let componentsRows = null

    if (componentValues.length === 0) {
        componentsRows = (
            <tr key={`${index}ni`}>
                <td>{index + 1}</td>
                <td> {item.operation.operationName}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        )
    } else {

     componentsRows = componentValues.map((opi, i) => {
       
        const slNo = i === 0 ? <td rowSpan={componentValues.length}>{index + 1}</td> : null
        const operationCell =
        i === 0 ? (
          <td  rowSpan={componentValues.length}> {item.operation.operationName}</td>
        ) : null


        return (
          <tr key={i}>
            {slNo}
            {operationCell}
            <td>{opi?.item?.itemName}</td>
            <td>{opi?.item?.itemCode}</td>
            <td>{opi?.item?.unit}</td>
            <td>{Number(opi.quantity).toFixed(2)}</td>
            <td>{(opi.quantity * opi.price).toFixed(2)}</td>
          </tr>
        )
      })
    } // end of else
      return componentsRows
    })

    return tbodies
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/icons/duotune/text/txt001.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
           Production Details
          </span>
        </div>
      </div>
      <div className='card-body'>
        <div className='ml-12'>
          <div className='row'>
            <div className='col-6'>
              <table className='table table-bordered table-hover'>
                <tbody>
                  <tr>
                    <th>Production Date</th>
                    <td>{details.productionDate ?? ''}</td>
                  </tr>
                  <tr>
                    <th>Product</th>
                    <td>{details?.product?.itemName}</td>
                  </tr>
                  <tr>
                    <th>Unit</th>
                    <td>{details?.product?.unit }</td>
                  </tr>
                  <tr>
                    <th>Quantity</th>
                    <td>{Number(details.quantity).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Batch No</th>
                    <td>{details.batchNo ?? ''}</td>
                 </tr>
                 
                </tbody>
              </table>
            </div>

            <div className='col-6'>
              <table className='table table-bordered table-hover'>
                <tbody>
                  <tr>
                    <th>Finished Product Warehouse</th>
                    <td>{details?.fwarehouse?.warehouseName}</td>
                  </tr>

                  <tr>
                    <th>Production No</th>
                    <td>{details?.finishgoods?.productionNo}</td>
                  </tr>

                  <tr>
                    <th>Raw Material Warehouse</th>
                    <td>{details?.rwarehouse?.warehouseName}</td>
                  </tr>

                  <tr>
                    <th>Issue No</th>
                    <td>{details?.issue?.issueNo}</td>
                  </tr>
                  
                  </tbody>
              </table>

            </div>  


            <div className='col-12'>
        
                  <div className='table-responsive'>
                    <table
                      className='table table-bordered table-hover'
                      style={{minWidth: 'max-content'}}
                    >
                      <thead>
                        <tr>
                        <th>SL</th>
                        <th>Route Stage</th>
                        <th style={{width: '25%'}}>Item</th>
                        <th>Code</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                         {tableBody()}
                      </tbody>
                    </table>
                  </div>
                
            </div>

            <div className='col-6'>
                <table className='table table-bordered table-hover'>
                    <tbody>
                        <tr>
                            <th>Raw Material Cost</th>
                            <td>{
                            details.productionoperations?.reduce((total, item) => {
                                const componentValues = Object.values(item.productioncomponents ?? {});
                                const sum = componentValues.reduce((total, opi) => {
                                    return total + Number(opi.quantity) * Number(opi.price)
                                }, 0)
                                return (Number(total) +Number(sum)).toFixed(2);
                            }, 0)
                            }</td>
                          
                        </tr>
                        <tr>
                            <th>Labor Cost</th>
                            <td>{Number(details.laborCost).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Factory Overhead</th>
                            <td>{Number(details.factoryOverhead).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th>Total Cost</th>
                            <td>{Number(details.totalCost).toFixed(2)}</td>
                        </tr>
                    </tbody>  
                </table>     
                </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductionDetails
