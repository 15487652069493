import React, {useState} from 'react'
import DataTable from '../components/DataTable'
import {KTSVG} from '../../_metronic/helpers'


function WipQcList (props) {

  const columns = [
    {value: 'id', title: 'Id',style:{textAlign: 'center'}},
    {value: 'orderNo', title: 'Order No',style:{textAlign: 'center'}},
    {value: 'operation', title: 'Operation',style:{textAlign: 'center'}},
    {value: 'qcDate', title: 'QC Date',style:{textAlign: 'center'}},
    {value: 'qcQty', title: 'QC Qty',style:{textAlign: 'center'}},
    {value: 'action',title: 'Action',actions: [
        {link: 'wip-qc-details', calAction: false}
      ],
      style:{textAlign: 'center'}
    }
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    orderNo: {
      filterType: 'like',
      filterValue: '',
    },
    operation: {
      filterType: 'like',
      filterValue: '',
    },
    qcDate: {
      filterType: 'like',
      filterValue: '',
    },
    qcQty: {
      filterType: 'like',
      filterValue: '',
    }

  }

  const url = process.env.REACT_APP_API_URL + 'wip_qc_dt'


  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Work-in-progress QC List
            </span>
          </div>
        </div>

        <div className='card-body'>


          <DataTable url={url} columns={columns} filters={filters} >

          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
          </span>

        </DataTable>


        </div>
      </div>
    </>
  )
}

export default WipQcList
