import React, { useState } from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'
import OperationCreate from '../components/OperationCreate'

function Operations(props) {

	const [newData, setNewData] = useState('');
	const [id, setId] = useState('');
	const [name, setName] = useState('');

	const onCreate = ({ id, name }) => {
		console.log(name);
		setNewData({ id: id, name: name });
	}

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{ value: 'operationName', title: 'Operation Name', style: { textAlign: 'left' } },
		{ value: 'remarks', title: 'remarks', style: { textAlign: 'left' } },
		{
			value: 'action', title: 'Action', actions: [
				{ link: 'edit', calAction: true }
			],
			style: { textAlign: 'center' }
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		operationName: {
			filterType: 'like',
			filterValue: '',
		},
		remarks: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'production_operation_dt'

	const onAction = (data) => {
		console.log(data)
		setId(data.id);
		setName(data.operationName);
	}

	return (
		<>
			<div className='card card-custom'>
				<div className='card-header'>
					<div className='card-title'>
						<KTSVG
							path='/media/icons/duotune/text/txt001.svg'
							className='svg-icon-1 svg-icon-primary'
						/>
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							Operations
						</span>
					</div>
				</div>

				<div className='card-body'>
					<OperationCreate onCreate={onCreate} id={id} name={name} />

					<DataTable url={url} columns={columns} filters={filters} newData={newData} onAction={onAction}>

						<span className='svg-icon svg-icon-md svg-icon-primary'>
							<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
						</span>

					</DataTable>


				</div>
			</div>
		</>
	)
}

export default Operations
