import React, { useState } from 'react';
import { KTSVG } from '../../_metronic/helpers';
import DataTable from '../components/DataTable';


function QcList (props) {

  const [newData, setNewData] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');

  const columns = [
    {value: 'id', title: 'Id', style:{textAlign: 'center'}},
    {value: 'qcDate', title: 'QC Date', style:{textAlign: 'center'}},
    {value: 'shiftName', title: 'Shift Name', style:{textAlign: 'left'}},
    {value: 'operation', title: 'Operation', style:{textAlign: 'left'}},
    {value: 'action',title: 'Action', actions: [
        {link: 'qc-details', calAction: false}
      ],
      style:{textAlign: 'center'}
    }
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    qcDate: {
      filterType: 'like',
      filterValue: '',
    },
    shiftName: {
      filterType: 'like',
      filterValue: '',
    },
    operation: {
        filterType: 'like',
        filterValue: '',
    }

  }

  const url = process.env.REACT_APP_API_URL + 'qc/list'

  const onAction = (data) => {
    console.log(data)
    setId(data.id);
    setName(data.operationName);
    
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              QC List
            </span>
          </div>
        </div>

        <div className='card-body'>


          <DataTable url={url} columns={columns} filters={filters}>

          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
          </span>

        </DataTable>


        </div>
      </div>
    </>
  )
}

export default QcList
