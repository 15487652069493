
function  validationErrorMsg(errors){
            
              let errorsMessage = [];

              if (errors !== undefined && typeof errors === 'object') {
                  let errorsObj = errors;
                  Object.keys(errorsObj).forEach(function (value) {
                      errorsObj[value].forEach(function (v) {
                          errorsMessage.push(v);
                      });

                  });

              } else if (errors  !== undefined && (typeof errors  === 'string' || errors  instanceof String)) {
                  //errorsMessage = errors ;
                  errorsMessage.push(errors );
              } else {
                  //errorsMessage = "Something went wrong";
                  errorsMessage.push("Something went wrong");
              }

              return errorsMessage
              

}

export default validationErrorMsg;