import React, {useState, useContext, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {getRequestOptions} from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import {AppContext} from '../context/AppContext'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment'

function DailyProductionReport (props) {
  const [date, setDate] = useState('')
  const [hasSubmit, setSubmit] = useState(false)
  const [reportData, setReportData] = useState([])
  const [loading, setLoading] = useState(false)
  const [buName, setBuName] = useState('')
  const [opChecked, setChecked] = useState([""]);//useState(["1"]);
  const [operations, upDateOperation] = useState([{id:1,name:'Cutting',checked:false},{id:2,name:'Hardening & Tempering',checked:false},{id:3,name:'Shot Peening',checked:false},{id:4,name:'Painting',checked:false}]);

  const {bu} = useContext(AppContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'get_business_units_details/' + bu,
        getRequestOptions()
      )
      const json = await response.json()
      setBuName(json.data.projectName)
    }
    if (bu !== '') {
      fetchData()
    }

    const a = operations.filter(item => item.checked === true);
    let b = [];
    a.forEach(item => {
      b.push(item.id.toString());
    })
    setChecked(b);
  }, [bu])


    // Add/Remove checked item from list
    const handleCheck = (event) => {
      var updatedList = [...opChecked];
      if (event.target.checked) {
        updatedList = [...opChecked, event.target.value];
      } else { 
        updatedList.splice(opChecked.indexOf(event.target.value), 1);
      
      }
      setChecked(updatedList);

      const newOperationsData = operations.map(item => {
        if (item.id == event.target.value) {
          item.checked = event.target.checked;
        }
        return item;
      }
      );
      upDateOperation(newOperationsData);
    };

  const handleSubmit = (evt) => {
    evt.preventDefault()
    setSubmit(true)
    getReportData()
  }

  const getReportData = () => {
    setLoading(true)

    const jwt = () => {
      return JSON.parse(localStorage.getItem('MyToken'))
    }
    const formData = {
      date: date,
      buId: bu,
      opIds: opChecked
    }

    const requestOptions = postRequestOptions(formData);

    var apiEnd = 'report/daily_production'

    fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        //console.log(resp)
        setLoading(false)
        if (resp.success === true) {
          setReportData(resp.data)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            html: resp.errorMessage,
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        console.log(error, 'catch the hoop')
      })
  }

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

   const excelExportEndPoint = process.env.REACT_APP_API_URL.split("api/")[0]+'report/daily_production_item_report_export/'+bu+'/'+date+'/'+opChecked.join(',');


   const token = { token: getCookie('MyToken') }

  //const operations = [{id:1,name:'Cutting'},{id:2,name:'Hardening & Tempering'},{id:3,name:'Shot Peening'},{id:4,name:'Painting'}]

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Daily Production Item
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='ml-12'>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <label
                  htmlFor='date'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Date <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <input
                    type='date'
                    name='date'
                    id='date'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className='form-control form-control-sm'
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className=' col-lg-3'>&nbsp;</div>
                <div className='col-lg-6'>
                  {operations.map((operation, index) => 
                  <div className='col-lg-6 mt-2' key={`a${index}`}>
                  <label className='form-check form-check-inline form-check-solid'  key={`b${index}`}>
                  <input
                    key={index}
                    className='form-check-input'
                    name={operation.name}
                    value={operation.id}
                    onChange={handleCheck}
                    checked={operation.checked}
                    type='checkbox'
                  />
                  <span className='fw-bold ps-2 fs-6'  key={`c${index}`}>{operation.name} </span>
                </label>
                </div>
                  )}
                  
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-lg-3'></div>
                <div className='col-lg-5'>
                  <button type='submit' className='btn btn-sm btn-success mt-2 mb-2'>
                    Ok
                  </button>
                </div>
              </div>
            </form>
          </div>

         { hasSubmit && <div className='col-12'>
            <h4 style={{textAlign: 'center'}}>{buName}</h4>
            <h4 style={{textAlign: 'center'}}>Daily Production Items</h4>
            <p style={{textAlign: 'center'}}>
              Date {date !== '' ? moment(date).format('D-MMM-YYYY') : ''}
            </p>

            <div className='d-flex' style={{justifyContent: 'flex-end'}} >
            <a href={`${excelExportEndPoint}?token=${token.token}`}  className="btn btn-success btn-sm mr-3 mb-3"><i className="flaticon2-download-1"></i>Excel Export</a>
            </div>

            <div className='table-responsive'>
              <table
                className='table table-bordered table-hover'
                style={{minWidth: 'max-content', marginBottom: '150px'}}
              >
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Item Name</th>
                    <th>Item Code</th>
                    { opChecked.includes('1') && <th>Cutting-WIP</th> }
                    { opChecked.includes('1') && <th>Cutting-QC</th> }
                    { opChecked.includes('2') && <th>Hardening & Tempering-WIP</th> }
                    { opChecked.includes('2') && <th>Hardening & Tempering-QC</th> }
                    { opChecked.includes('3') && <th>Shot Peening-WIP</th> }
                    { opChecked.includes('3') && <th>Shot Peening-QC</th> }
                    { opChecked.includes('4') && <th>Painting-WIP</th> }
                    { opChecked.includes('4') && <th>Paiting-QC</th> }
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      { opChecked.includes('1') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                     { opChecked.includes('1') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                     { opChecked.includes('2') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                      { opChecked.includes('2') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                      { opChecked.includes('3') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                     { opChecked.includes('3') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                      { opChecked.includes('4') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                      { opChecked.includes('4') && <td>
                        <p>
                          <Skeleton count={14} />
                        </p>
                      </td>
                      }
                    </tr>
                  )}
                  {!loading &&
                    reportData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.itemName}</td>
                        <td>{item.itemCode}</td>
                        { opChecked.includes('1') && <td>{item.wipCutting}</td>}
                        { opChecked.includes('1') && <td>{item.cuttingQc}</td>}
                        { opChecked.includes('2') && <td>{item.wipHardeningTempering}</td>}
                        { opChecked.includes('2') && <td>{item.hardeningTemperingQc}</td>}
                        { opChecked.includes('3') && <td>{item.wipShotPeening}</td>}
                        { opChecked.includes('3') && <td>{item.shotPeeningQc}</td>}
                        { opChecked.includes('4') && <td>{item.wipPainting}</td>}
                        { opChecked.includes('4') &&<td>{item.paintingQc}</td>}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        }
        </div>
      </div>
      {/* <ToastContainer /> */}
  
       {/* <pre>
          {JSON.stringify(opChecked, null, 2)}
        </pre> */}
    </>
  )
}

export default DailyProductionReport
