/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import AsyncSelect from 'react-select/async';

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};

function OperationCreate(props) {
	let history = useHistory()
	const [id, setId] = useState('')
	const [operationName, setOperationName] = useState('')
	const [remarks, setRemarks] = useState('')

	const myRef = useRef()

	useEffect(() => {
		//setoperationName(props.name);
		if (id !== props.id || operationName !== props.name) {
			setId(props.id)
			getOperationDetails(props.id)
			window.scrollTo(0, 0)
		}
	}, [props.id, props.name])

	const getOperationDetails = (operationId) => {
		fetch(process.env.REACT_APP_API_URL + 'production_operation_details/' + operationId, getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setOperationName(resp.operationName);
				setRemarks(resp.remarks);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


	const handleSubmit = (evt) => {
		evt.preventDefault()
		if (id !== '') {
			var text = 'You want to update this Operation!'
		} else {
			text = 'You want to add this Opertion!'
		}
		Swal.fire({
			title: 'Are you sure?',
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then((result) => {
			if (result.value) {
				const jwt = () => {
					return JSON.parse(localStorage.getItem('MyToken'))
				}
				const formData = {
					id: id,
					operationName: operationName,
					remarks: remarks
				}

				const requestOptions = postRequestOptions(formData);

				if (id !== '') {
					var apiEnd = 'production_operation_update'
				} else {
					apiEnd = 'production_operation_create'
				}

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp)

						if (resp.success === true) {
							if (id !== '') {
								var successMsg = [`Operation ${resp.data.name} updated`]
							} else {
								successMsg = [`Operation ${resp.data.name} added`]
							}

							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})
							setId('')
							setOperationName('')
							setRemarks('');
							props.onCreate({ id: resp.data.id, name: resp.data.name })
						} else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: resp.errorMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	return (
		<>
			<div className='card-body'>
				<div className='ml-12'>
					<form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group row'>
									<label
										htmlFor='operationName'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Operations Name<span className='required text-danger'></span>
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='operationName'
											id='operationName'
											ref={myRef}
											value={operationName}
											onChange={(e) => setOperationName(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>
								<div className='form-group row'>
									<label
										htmlFor='remarks'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Remarks
									</label>
									<div className='col-lg-8'>
										<textarea name="remarks" id="remarks" value={remarks} className="form-control form-control-sm"
											onChange={(e) => setRemarks(e.target.value)}
										/>


									</div>
								</div>
							</div>

						</div>

						<div className='row'>
							<div className='col-4'></div>
							<div className='col-6'>
								<input
									type='submit'
									className='btn btn-sm btn-success mt-2'
									value={id !== '' ? 'Update' : 'Save'}
								/>
							</div>
						</div>
						{/* <pre>
            {JSON.stringify(operationName, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
					</form>
				</div>
				{/* <ToastContainer /> */}
			</div>
		</>
	)
}

export default OperationCreate
