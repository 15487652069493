/* eslint-disable no-unused-vars */
import {useState,createContext,useEffect } from "react";

export const AppContext = createContext();


function AppContextProvider(props) {
    const [bu,setBu] = useState(JSON.parse(localStorage.getItem('manuAppInfo'))!=null? JSON.parse(localStorage.getItem('manuAppInfo')).businessUnit:"");

   useEffect(() => {
       // getBu();
    }, [bu]);

    const getBu =  () => {
        const manuAppInfo =  JSON.parse(localStorage.getItem('manuAppInfo'));
        if(typeof manuAppInfo!== "undefined" && manuAppInfo!== null){
            setBu(manuAppInfo.businessUnit);

        }

    }

    const  changeBu= (manuAppInfo) =>{
        setBu(manuAppInfo.businessUnit);
        localStorage.setItem('manuAppInfo', JSON.stringify(manuAppInfo))
    }


   const value = {bu,changeBu};
   
    return (
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    );


    
}

export default AppContextProvider;