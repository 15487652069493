import React, {useState} from 'react'
import DataTable from '../components/DataTable'
import {KTSVG} from '../../_metronic/helpers'


function ProductionList (props) {

  const columns = [
    {value: 'id', title: 'Id',style:{textAlign: 'center'}},
    {value: 'productionDate', title: 'Production Date',style:{textAlign: 'center'}},
    {value: 'product', title: 'product',style:{textAlign: 'center'}},
    {value: 'batchNo', title: 'Batch No',style:{textAlign: 'center'}},
    {value: 'quantity', title: 'Quantity',style:{textAlign: 'center'}},
    {value: 'action',title: 'Action',actions: [
        {link: 'production-details', calAction: false}
      ],
      style:{textAlign: 'center'}
    }
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    productionDate: {
      filterType: 'like',
      filterValue: '',
    },
    product: {
      filterType: 'like',
      filterValue: '',
    },
    quantity: {
        filterType: 'like',
        filterValue: '',
    },
    batchNo: {
      filterType: 'like',
      filterValue: '',
    }

  }

  const url = process.env.REACT_APP_API_URL + 'production_dt'


  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Production List
            </span>
          </div>
        </div>
        <div className='card-body'>
          <DataTable url={url} columns={columns} filters={filters} >
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
          </span>
        </DataTable>
        </div>
      </div>
    </>
  )
}

export default ProductionList
