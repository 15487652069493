/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { getRequestOptions } from '../components/GetToken'
import { AppContext } from '../context/AppContext'
import { KTSVG } from '../../_metronic/helpers'
import { Spinner } from 'react-bootstrap-v5'

function WipQcToDoList() {
    const { bu } = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [qcToDoList, setQcToDoList] = useState([]);

    useEffect(() => {
        getQcToDoList();
    }, []);

    const getQcToDoList = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_URL}wip_qc_to_do_list/` + bu, getRequestOptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                setLoading(false);
                setQcToDoList(resp.data);
            })
    }

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className='card-title'>
                        <KTSVG
                            path='/media/icons/duotune/text/txt001.svg'
                            className='svg-icon-1 svg-icon-primary'
                        />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            QC To DO List
                        </span>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='ml-12'>
                        {loading &&
                            <div className="d-flex justify-content-center">
                                <Spinner animation='grow' />
                                <Spinner animation='grow' />
                                <Spinner animation='grow' />
                            </div>
                        }
                        <div className="row m-5">
                            {qcToDoList.length > 0 && qcToDoList.map((item, index) => {
                                return (
                                    <table className="table table-bordered table-hover" key={index}>
                                        <thead>
                                            <tr>
                                                <th colSpan="8" className="text-left"><h2>{item.operationName}</h2></th>
                                            </tr>
                                            <tr>
                                                <th>SN</th>
                                                <th>Order No</th>
                                                <th>Date</th>
                                                <th>Production Item</th>
                                                <th>Order Qty</th>
                                                <th>Production Qty</th>
                                                <th>QC Passed Qty</th>
                                                <th>Damaged Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item.data.map((value, key) =>
                                                    <tr key={key}>
                                                        <td>{key + 1}</td>
                                                        <td>{value.orderNo}</td>
                                                        <td>{value.orderDate}</td>
                                                        <td>{value.itemName}</td>
                                                        <td>{value.orderQty}</td>
                                                        <td>{value.productionQty}</td>
                                                        <td>{value.qcPassedQty}</td>
                                                        <td>{value.qcDamageQty}</td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                )
                            }
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WipQcToDoList;