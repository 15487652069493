/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { KTSVG } from '../../_metronic/helpers'
import { AppContext } from '../context/AppContext'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper';
import Swal from 'sweetalert2';

function GeneralSetting() {
    const { bu } = useContext(AppContext);
    const [accountingPosting, setAccountingPosting] = useState('no');
    const [batchNoExpiryDate, setBatchNoExpiryDate] = useState('off');

    useEffect(() => {
        getAccountingPosting();
    }, [bu]);

    function getAccountingPosting() {
        fetch(`${process.env.REACT_APP_API_URL}get_general_setting/` + bu, getRequestOptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                if (resp.data.length > 0) {
                    resp.data.map((item) => {
                        if (item.config_key === "accounting_posting") {
                            setAccountingPosting(item.config_value);
                        }
                        if (item.config_key === "batch_no_expiry_date") {
                            setBatchNoExpiryDate(item.config_value);
                        }
                    })
                }

            })
    }

    const handleSubmit = () => {
        const formData = {
            bu: bu,
            accountingPosting: accountingPosting,
            batchNoExpiryDate: batchNoExpiryDate
        };
        const requestOptions = postRequestOptions(formData);

        fetch(process.env.REACT_APP_API_URL + "general_setting_save", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                if (resp.success === true) {
                    Swal.fire({
                        position: "top-right",
                        icon: "success",
                        title: "Configuration Changed",
                        showConfirmButton: false,
                        timer: 1500
                    });
                }
                else {
                    var errorsMessage = [];

                    if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                            });

                        });

                    } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        errorsMessage.push(resp.errorMessage);
                    } else {

                        errorsMessage.push("Something went wrong");
                    }
                    Swal.fire({
                        icon: 'error',
                        title: resp.heading,
                        text: errorsMessage,
                    })
                }


            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }


    return (
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        General Configuration
                    </span>
                </div>
            </div>
            <div className='card-body'>
                <div className='ml-12'>
                    <div className="row">
                        <div className='form-group row'>
                            <label htmlFor='operationt' className='col-lg-4 col-form-label'
                                style={{ textAlign: 'right' }}>
                                Accounting Posting <span className='required text-danger'></span>
                            </label>
                            <div className="col-lg-5 col-form-label">
                                <div className="radio">
                                    <label className="form-check form-check form-check-solid">
                                        <input className='form-check-input' type="radio" name="accountingPosting" value={accountingPosting} checked={accountingPosting === "no"} onChange={() => setAccountingPosting('no')} />
                                        <span></span>
                                        No
                                    </label>
                                    <label className="form-check form-check form-check-solid mt-3">
                                        <input className='form-check-input' type="radio" name="accountingPosting" value={accountingPosting} checked={accountingPosting === "yes"} onChange={() => setAccountingPosting('yes')} />
                                        <span></span>
                                        Yes
                                    </label>
                                </div>
                            </div>
                        </div>

                        
                        <div className='form-group row'>
                            <label htmlFor='operationt' className='col-lg-4 col-form-label'
                                style={{ textAlign: 'right' }}>
                                Batch No and Expiry Date on Finished Goods Delivery <span className='required text-danger'></span>
                            </label>
                            <div className="col-lg-5 col-form-label">
                                <div className="radio">
                                    <label className="form-check form-check form-check-solid">
                                        <input className='form-check-input' type="radio" name="batchNoExpiryDate" value={batchNoExpiryDate} checked={batchNoExpiryDate === "on"} onChange={() => setBatchNoExpiryDate('on') } />
                                        <span></span>
                                        On
                                    </label>
                                    <label className="form-check form-check form-check-solid mt-3">
                                        <input className='form-check-input' type="radio" name="batchNoExpiryDate" value={batchNoExpiryDate} checked={batchNoExpiryDate === "off"} onChange={() => setBatchNoExpiryDate('off')} />
                                        <span></span>
                                        Off
                                    </label>
                                </div>
                            </div>
                    </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-5">
                    </div>
                    <div className="col-6">
                        <button type="submit" className="btn btn-primary btn-sm mb-5" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GeneralSetting;
