import React, { useContext, useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import { AppContext } from '../context/AppContext'

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.35em + 1.1rem + 2px)',
    minHeight: '35px',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
}

function QcCutting(props) {
  const [qcDate, setQcDate] = useState('')
  const [shift, setShift] = useState('')
  const [warehouse, setWarehouse] = useState('')
  const [warehouseOptions, setWarehouseOptions] = useState([])
  const [qcItems, setQcItems] = useState([
    {
      product: '',
      unit: '',
      productionQty: 0,
      productionPrice: 0,
      qcPassedQty: 0,
      qcFailedQty: 0,
    }
  ])

  const {bu} = useContext(AppContext)

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + 'get_warehouse_bu_wise/' + bu, getRequestOptions())
      const json = await response.json()
      setWarehouseOptions(json.data)
    }
    if(bu!==""){
      fetchData()
    }
  }, [bu])

  const getShift = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'shift_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'shift_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {...element, label: `${element.shiftName}`, value: element.shiftId}
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }

  const handleShiftChange = (shift) => {
    setShift(shift)
  }

  const getProduct = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'item/manufacturing_item_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'item/manufacturing_item_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {
              ...element,
              id: element.itemId,
              label: `${element.itemName} ${element.itemCode}`,
              value: element.itemId,
            }
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }



  const onProductChange = (index) => (item) => {
 
    const fetchData = async () => {
      try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}qc_operation_production_quantity/${bu}/${item.id}/${qcDate}/4`, getRequestOptions())
      const data = await response.json()
      const newItems = qcItems.map(function (row, rindex) {
        if (index !== rindex) return row;
        return { ...row, product: item, unit: item.unitName,productionQty:data.quantity,productionPrice:0,qcPassedQty:0,qcFailedQty:0 }
      })
      setQcItems(newItems);
    } catch (error) {
      console.log(error);
    }
    }
    if(qcDate !=""){
    fetchData();
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        html: "Please select Work-in-progress date first.",
      })
    }
  }



  const onQcPassedQtyChange = (index) => (e) => {
    const newQcItems = [...qcItems]
    newQcItems[index].qcPassedQty = e.target.value
    setQcItems(newQcItems)
  }

  const onQcFailedQtyChange = (index) => (e) => {
    const newQcItems = [...qcItems]
    newQcItems[index].qcFailedQty = e.target.value
    setQcItems(newQcItems)
  }

  const onProductionPriceChange = (index) => (e) => {
    const newQcItems = [...qcItems]
    newQcItems[index].productionPrice = e.target.value
    setQcItems(newQcItems)
  }

  const removeQcItem = (index) => {
    const newQcItems = [...qcItems]
    newQcItems.splice(index, 1)
    setQcItems(newQcItems)
  }

  const addQcItem = () => {
    setQcItems(
      qcItems.concat([
        {
          product: '',
          unit: '',
          productionQty: 0,
          productionPrice: 0,
          qcPassedQty: 0,
          qcFailedQty: 0,
        },
      ])
    )
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You want to QC this Painting!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  
    if(confirm.value){

      const formData = {
        qcDate: qcDate,
        shiftId: shift.value,
        warehouse: warehouse,
        qcItems: qcItems,
        buId: bu
      }

      console.log(formData);
      const requestOptions = postRequestOptions(formData);

      let response = await fetch(`${process.env.REACT_APP_API_URL}qc/paintaing/save`,requestOptions);

      let respData = await response.json();

      console.log(respData);
      
      if(response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'QC Data Saved Successfully',
        })

        setQcDate("");
        setShift("");
        setWarehouse("");
        setQcItems([{
          product: '',
          unit: '',
          productionQty: 0,
          productionPrice: 0,
          qcPassedQty: 0,
          qcFailedQty: 0,
        }]);

      } else {
        Swal.fire({
          icon: 'error',
          title: respData.heading,
          html: respData.errorMessage,
        })
      }
    }
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              QC Painting
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='ml-12'>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <label
                  htmlFor='qcDate'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Date <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <input
                    type='date'
                    name='qcDate'
                    id='qcDate'
                    value={qcDate}
                    onChange={(e) => setQcDate(e.target.value)}
                    className='form-control form-control-sm'
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='operationName'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Shift <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <AsyncSelect
                    value={shift}
                    defaultOptions
                    loadOptions={getShift}
                    onChange={handleShiftChange}
                    getOptionLabel={(option) => (
                      <>
                        <KTSVG
                          path='/media/svg/icons/Code/Time-schedule.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {option.label} </span>
                        <br></br>
                        <KTSVG
                          path='media/svg/icons/Home/Timer.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {`${option.startTime} - ${option.endTime}`} </span>
                        <br></br>
                      </>
                    )}
                  />
                </div>
              </div>

              <div className='form-group row' style={{marginTop:'10px'}}>
                <label
                  htmlFor='operationName'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Warehouse <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                    <select  className='form-control form-control-sm' value={warehouse} onChange={(e) => setWarehouse(e.target.value)}>
                        <option value="">Select Warehouse</option>
                        {
                          warehouseOptions.map(function (item, id) {
                            return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
                          })
                        }
					           </select>
                </div>
              </div>  

              <div className='col-12'>
                <div className='table-responsive'>
                  <table
                    className='table table-bordered table-hover'
                    style={{minWidth: "max-content",marginBottom:"150px"}}
                  >
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th style={{width: '25%'}}>Product</th>
                        <th>Unit</th>
                        <th>Production Qty</th>
                        <th>QC Passed Qty</th>
                        {/* <th>Production Price</th> */}
                        <th>Damage Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {qcItems.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <AsyncSelect
                              value={item.product}
                              defaultOptions
                              loadOptions={getProduct}
                              placeholder='Select Product'
                              onChange={onProductChange(index)}
                              styles={customStylesSelect}
                            />
                          </td>
                          <td>
                            <input type='text' className='form-control form-control-sm' value={item.unit} readOnly />
                          </td>
                          <td>
                            <input
                              type='number'
                              className='form-control form-control-sm'
                              value={item.productionQty}
                              readOnly
                            />
                          </td>
                          <td>
                            <input type='number' className='form-control form-control-sm' value={item.qcPassedQty} onChange={onQcPassedQtyChange(index)} />
                          </td>
                          {/* <td>
                            <input type='number' className='form-control form-control-sm' value={item.productionPrice} onChange={onProductionPriceChange(index)} />
                          </td> */}
                          <td>
                            <input type='number' className='form-control form-control-sm' value={item.qcFailedQty} onChange={onQcFailedQtyChange(index)} />
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-icon btn-light btn-active-light-danger  btn-sm'
                              onClick={() => removeQcItem(index)}
                            >
                              <span className='svg-icon svg-icon-md svg-icon-danger'>
                                <KTSVG path={'/media/svg/icons/General/Trash.svg'} />
                              </span>
                            </button>
                            {/* delete */}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan='9'>
                          <div className='form-group row'>
                            <div className='col-lg-2'>
                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                onClick={addQcItem}
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'></div>
                <div className='col-6'>
                  <input type='submit' className='btn btn-sm btn-success mt-2' value={'Save'} />
                </div>
              </div>
              {/* <pre>{JSON.stringify(qcItems, null, 2)}</pre> */}
            </form>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default QcCutting
