import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {postRequestOptions} from '../helpers/Fetchwrapper'

function ExpenseCreate (props) {

    let history = useHistory();
    const [id, setId] = useState("");
    const [expenseType, setExpenseType] = useState("");

    useEffect(() => {
        if(id !== props.id || expenseType !== props.expenseType){
            setId(props.id);
            setExpenseType(props.expenseType);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ props.id, props.expenseType])

    const  handleSubmit = evt => {
        evt.preventDefault();
        if (id !== "") {
            var text = 'You want to update this Expense Type!';
        }
        else {
            text = 'You want to add this Expense Type!';
        }
        Swal.fire({
            title: 'Are you sure?',
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = () => { return JSON.parse(localStorage.getItem('MyToken')); }
                const formData = {
                    id: id,
                    expenseType: expenseType
                };
                const requestOptions = postRequestOptions(formData);
                if (id !== "") {
                    var apiEnd = "expense_type_update";
                }
                else {
                    apiEnd = "expense_type_add";
                }

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    console.log(resp);

                    if (resp.success === true) {
                        if (id !== "") {
                            var successMsg = [`Expense Type '${resp.data.expenseType}' updated`];
                        }
                        else {
                            successMsg = [`Expense Type '${resp.data.expenseType}' added`];;
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: successMsg,
                        })
                        setId("");
                        setExpenseType("");
                        props.onCreate({id:resp.data.id,expenseType:resp.data.expenseType})
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: resp.errorMessage
                        })
                    }
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });

            }
        })
    }

    return (
        <>
            <div className="ml-12">               
                <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                        <label htmlFor="expenseType" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Expense Type <span className="required text-danger"></span></label>
                        <div className="col-lg-5">
                            <input type="text" name="expenseType" id="expenseType" value={expenseType} onChange={(e) => setExpenseType(e.target.value)} className="form-control form-control-sm"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                        </div>
                        <div className="col-6">
                            <input type="submit" className="btn btn-sm btn-success me-2" value={id !== "" ? 'Update' : 'Save'} />
                            <button type="reset" className="btn btn-sm btn-secondary" onClick={() => history.goBack()}>Cancel</button>
                        </div>
                    </div>
                    {/* <pre>
                        {JSON.stringify(expenseType, null, 2)}
                        {JSON.stringify(id, null, 2)}
                    </pre> */}
                </form>
            </div>
            <ToastContainer />
        </>
    )

}

export default ExpenseCreate;