/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { KTSVG } from '../../_metronic/helpers'
import Select from 'react-select';
import { getRequestOptions } from '../components/GetToken'
import { AppContext } from '../context/AppContext'
import Swal from 'sweetalert2'
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import WipWorkingDurationModal from "../components/WipWorkingDurationModal";

function OrderWipCreate() {
    const { bu } = useContext(AppContext);
    const [allOperations, setAllOperations] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState('');
    const [orderInWip, setOrderInWip] = useState([]);
    const [wipModal, setWipModal] = useState(false);
    const [modalData, setModalData] = useState('');

    useEffect(() => {
        getAllProductOperation();
    }, []);

    useEffect(() => {
        if (selectedOperation !== '') {
            getWipOrder();
        }
    }, [selectedOperation, bu]);

    const getAllProductOperation = () => {
        fetch(`${process.env.REACT_APP_API_URL}all_product_operation`, getRequestOptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                const operations = resp.data.map((operation) => {
                    return {
                        value: operation.idOperations,
                        label: operation.operationName
                    };
                });
                setAllOperations(operations);
            })

    }

    const getWipOrder = () => {
        fetch(`${process.env.REACT_APP_API_URL}get_order_in_wip/` + selectedOperation?.value + '/' + bu, getRequestOptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                setOrderInWip(resp.data);
            })
    }

    const handleSelectedOperationChange = (operation) => {
        setSelectedOperation(operation);
    }

    const handleWipModalChange = (item) => {
        setWipModal(true);
        setModalData(item);
    }

    const wipModalClose = () => {
        setWipModal(false);
    }

    const handleWipClose = (item) => {
        //console.log(item);
        Swal.fire({
            title: 'Are you sure?',
            text: "You wan't to close this wip!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
            fetch(`${process.env.REACT_APP_API_URL}close_order_wip/` + item?.idOrderOperation, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                }
                )
                .then((resp) => {
                    if (resp.success === true) {
                        const successMsg = resp.successMessage
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: successMsg,
                        })
                        getWipOrder();
                    } else {
                        const errorsMessage = validationErrorMsg(resp.errorMessage).join('<br>')
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            html: errorsMessage,
                        })
                    }
                })
        }
        });
    }


    return (
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Work-in-progress Create
                    </span>
                </div>
            </div>

            <div className='card-body'>
                <div className='ml-12'>
                    <div className='form-group row'>
                        <label htmlFor='operationt' className='col-lg-3 col-form-label'
                            style={{ textAlign: 'right' }}>
                            Select Operation <span className='required text-danger'></span>
                        </label>
                        <div className='col-lg-5'>
                            <Select
                                value={selectedOperation}
                                onChange={handleSelectedOperationChange}
                                options={allOperations}
                            />
                        </div>
                    </div>
                    <div className="table-responsive m-5">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Sl</th>
                                    <th>Order No</th>
                                    <th>Schedule Date</th>
                                    <th>Production Item</th>
                                    <th>Production Qty</th>
                                    <th>Completed Qty</th>
                                    <th>Opening Qty</th>
                                    <th>Remaining Qty</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orderInWip.map((item, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.productionOrderNo}</td>
                                            <td>{item.scheduleDate}</td>
                                            <td>{item.itemName}</td>
                                            <td>{item.poQty}</td>
                                            <td>{item.completedQty}</td>
                                            <td>{item.openingQty ? item.openingQty : 0}</td>
                                            <td>{item.remainingQty}</td>
                                            <td>
                                                {/* button group  for two btn start and stop*/}
                                                <div className="btn-group" role="group" aria-label="Basic example"> 
                                                <button type="button" className="btn btn-primary btn-sm" onClick={() => handleWipModalChange(item)}>Start</button>
                                                <button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: '5px' }} onClick={()=> handleWipClose(item)}>Close</button> 
                                                </div>                                              
                                            </td>
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                    <WipWorkingDurationModal modalData={modalData} bu={bu} operation={selectedOperation} show={wipModal} handleClose={wipModalClose} />
                </div>
            </div>
        </div>
    );

}

export default OrderWipCreate;