const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function postRequestOptions(body) {
	//var jwt = { token: getCookie('MyToken') };
	let token = localStorage.getItem('MyToken');
	const  jwt = JSON.parse(token);
	

	let requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json','Accept': 'application/json' ,'Authorization': 'Bearer ' + jwt.token },
        body: JSON.stringify(body)
	};
	return requestOptions;
}

function getRequestOptions() {
	//let jwt = { token: getCookie('MyToken') };
	let token = localStorage.getItem('MyToken');
	const  jwt = JSON.parse(token);
	console.log(jwt);
	console.log(jwt.token);

	let requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + jwt.token }
	};
	return requestOptions;
}

function deleteRequestOptions() {
	//let jwt = { token: getCookie('MyToken') };
	let token = localStorage.getItem('MyToken');
	const  jwt = JSON.parse(token);
	console.log(jwt);

	let requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': 'Bearer ' + jwt.token }
	};
	return requestOptions;
}
export { postRequestOptions, getRequestOptions, deleteRequestOptions };

