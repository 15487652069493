import React, { useContext, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions} from '../helpers/Fetchwrapper'
import { AppContext } from '../context/AppContext'

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.35em + 1.1rem + 2px)',
    minHeight: '35px',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
}

function WipHardeningTempering (props) {
  const [wipDate, setWipDate] = useState('')
  const [shift, setShift] = useState('')
  const [wipItems, setWipItems] = useState([
    {
      product: '',
      unit: '',
      openingQty: '',
      quantity: '',
      closingQty: ''
    },
  ])

  const {bu} = useContext(AppContext);

  
  const getShift = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'shift_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'shift_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {...element, label: `${element.shiftName}`, value: element.shiftId}
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }

  const handleShiftChange = (shift) => {
    setShift(shift)
  }

  const getProduct = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'item/manufacturing_item_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'item/manufacturing_item_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {
              ...element,
              id: element.itemId,
              label: `${element.itemName} ${element.itemCode}`,
              value: element.itemId,
            }
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }

  const onProductChange1 = (index) => (item) => {
    // const newItems = wipItems.map(function (row, rindex) {
    // 	if (index !== rindex) return row;
    // 	return { ...row, product: item, unit: item.unitName,itemCode:item.itemCode }
    // });
    // setWipItems(newItems);
    getProductionItemDetails(item, index)
  }

  const onProductChange = (index) => (item) => {
 
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + 'hardening_tempering_opening/' +bu +'/'+item.itemId+ '/' + wipDate, getRequestOptions())
      const data = await response.json()
      const newItems = wipItems.map(function (row, rindex) {
        if (index !== rindex) return row;
        return { ...row, product: item, unit: item.unitName,itemCode:item.itemCode,openingQty:data.openingQty,quantity:'',closingQty:''}
      })
      setWipItems(newItems);
    }
    if(wipDate !=""){
    fetchData();
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        html: "Please select Work-in-progress date first.",
      })
    }
  }

  const addWipItem = () => {
    setWipItems(
      wipItems.concat([{product: '', itemCode: '', unit: '', quantity: '', openingQty: '', closingQty: ''}])
    )
  }

 
  const removeWipItem = (index) => () => {
    setWipItems(wipItems.filter((row, rindex) => index !== rindex))
  }

  const onWipQtyChange = (index) => (evt) => {
    const newItems = wipItems.map(function (row, rindex) {
      if (index === rindex) {
        row.quantity = evt.target.value;
        row.closingQty = row.openingQty - row.quantity;
      }
      return row
    })
    setWipItems(newItems)
  }



  const getProductionItemDetails = (item, index) => {
    const initItems = wipItems.map(function (row, rindex) {
      if (index !== rindex) {
        return row
      } else {
        row.product = item
        row.unit = item.unitName
        row.itemCode = item.itemCode
        row.openingQty = ''
        row.quantity = ''
        row.closingQty = ''
        return row
      }
    })

    setWipItems(initItems)

    fetch(
      process.env.REACT_APP_API_URL + 'hardening_tempering_opening/'+bu +'/' + item.itemId+ '/' + wipDate,
      getRequestOptions()
    )
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        const newItems = wipItems.map(function (row, rindex) {
          if (index !== rindex) {
            return row;
          } else {
            row.product = item
            row.unit = item.unitName
            row.itemCode = item.itemCode
            row.openingQty = resp.openingQty
            row.quantity = ''
            row.closingQty = ''
            return row
        }
        })

        //console.log(newItems);
        setWipItems(newItems)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    var text = 'You want to add this Work-in-progress Hardening and Tempering!'

    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        const jwt = () => {
          return JSON.parse(localStorage.getItem('MyToken'))
        }
        const formData = {
          wipDate: wipDate,
          shift: shift.value,
          wipItems: wipItems,
          bu: bu
        }


        const requestOptions = postRequestOptions(formData);

        var apiEnd = 'wip_hardening_tempering_save'

        fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp)

            if (resp.success === true) {
              var successMsg = [`Working-in-progress Hardening and Tempering added successfully`]

              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })
              setWipDate('');
              setShift('');
              setWipItems([{product: '', itemCode: '', unit: '', quantity: '',openingQty: '', closingQty: ''}]);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: resp.errorMessage,
              })
            }
          })
          .catch((error) => {
            console.log(error, 'catch the hoop')
          })
      }
    })
  }


  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Work-in-progress Hardening and Tempering
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='ml-12'>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <label
                  htmlFor='wipDate'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Date <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <input
                    type='date'
                    name='wipDate'
                    id='wipDate'
                    value={wipDate}
                    onChange={(e) => setWipDate(e.target.value)}
                    className='form-control form-control-sm'
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='operationName'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Shift <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <AsyncSelect
                    value={shift}
                    defaultOptions
                    loadOptions={getShift}
                    onChange={handleShiftChange}
                    getOptionLabel={(option) => (
                      <>
                        <KTSVG
                          path='/media/svg/icons/Code/Time-schedule.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {option.label} </span>
                        <br></br>
                        <KTSVG
                          path='media/svg/icons/Home/Timer.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {`${option.startTime} - ${option.endTime}`} </span>
                        <br></br>
                      </>
                    )}
                  />
                </div>
              </div>  
              

              <div className='col-12' style={{marginTop:'10px'}}>
                <div className='table-responsive'>
                  <table
                    className='table table-bordered table-hover'
                    style={{minWidth: 'max-content',marginBottom:"150px"}}
                  >
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th style={{width: '25%'}}>Product</th>
                        <th>Unit</th>
                        <th>Opening Qty</th>
                        <th>Production Qty</th>
                        <th>Closing Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wipItems.map((item, index) => 
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td><AsyncSelect
                            key={index}
                            value={item.product}
                            defaultOptions
                            loadOptions={getProduct}
                            placeholder='Select Product'
                            onChange={onProductChange(index)}
                            styles={customStylesSelect}
                           /> 
                           </td>
                            <td>{item.unit}</td>
                           <td>
                           <div className='col-sm-4'>
                                <input
                                className='form-control form-control-sm '
                                type='number'
                                value={item.openingQty} readOnly/>
                            </div>
                            </td>
                            <td>
                            <div className='col-sm-6'>
                                <input readOnly={item.product === ''}
                                className='form-control form-control-sm' style={{minWidth: '90px'}}
                                type='number'
                                value={item.quantity}  onChange={onWipQtyChange(index)}/>
                            </div>
                            </td>
                            <td>
                            <div className='col-sm-4'>
                                <input
                                className='form-control form-control-sm'
                                type='number'
                                value={item.closingQty} readOnly/>
                            </div>
                            </td>
                            <td>
                            
                              <div className='col-2'>
                                <button
                                  type='button'
                                  className='btn btn-icon btn-light btn-active-light-danger  btn-sm'
                                  onClick={removeWipItem(index)}
                                >
                                  <span className='svg-icon svg-icon-md svg-icon-danger'>
                                    <KTSVG path={'/media/svg/icons/General/Trash.svg'} />
                                  </span>
                                </button>
                              </div>
                             
                            </td>     

                        </tr>
                      ) }
                     <tr>
                        <td colSpan='9'>
                          <div className='form-group row'>
                            <div className='col-lg-2'>
                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                onClick={addWipItem}
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'></div>
                <div className='col-6'>
                  <input type='submit' className='btn btn-sm btn-success mt-2' value={'Save'} />
                </div>
              </div>
              {/* <pre>{JSON.stringify(wipItems, null, 2)}</pre> */}
            </form>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default WipHardeningTempering
