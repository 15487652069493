import React, {useState} from 'react'
import DataTable from '../components/DataTable'
import {KTSVG} from '../../_metronic/helpers'


function BomList (props) {

  const [newData, setNewData] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');

  const  onCreate = ({id,name}) => {
    console.log(name);
   setNewData({ id: id, name: name } );
  }

  const columns = [
    {value: 'id', title: 'Id',style:{textAlign: 'center'}},
    {value: 'productName', title: 'Product',style:{textAlign: 'center'}},
    {value: 'quantity', title: 'Quantity',style:{textAlign: 'center'}},
    {value: 'bomType', title: 'BOM Type',style:{textAlign: 'center'}},
    {value: 'action',title: 'Action',actions: [
        {link: 'bom-details', calAction: false}
      ],
      style:{textAlign: 'center'}
    }
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    productName: {
      filterType: 'like',
      filterValue: '',
    },
    quantity: {
      filterType: 'like',
      filterValue: '',
    },
    bomType: {
        filterType: 'like',
        filterValue: '',
    }

  }

  const url = process.env.REACT_APP_API_URL + 'bom_dt'

  const onAction = (data) => {
    console.log(data)
    setId(data.id);
    setName(data.operationName);
    
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Bom List
            </span>
          </div>
        </div>

        <div className='card-body'>


          <DataTable url={url} columns={columns} filters={filters} newData={newData} onAction={onAction}>

          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
          </span>

        </DataTable>


        </div>
      </div>
    </>
  )
}

export default BomList
