import React, { useContext, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import { AppContext } from '../context/AppContext'

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.35em + 1.1rem + 2px)',
    minHeight: '35px',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
}

function QcCutting(props) {
  const [qcDate, setQcDate] = useState('')
  const [shift, setShift] = useState('')
  const [qcItems, setQcItems] = useState([
    {
      product: '',
      unit: '',
      productionQty: 0,
      qcPassedQty: 0,
      qcFailedQty: 0,
    },
  ])

  const {bu} = useContext(AppContext)

  const getShift = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'shift_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'shift_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {...element, label: `${element.shiftName}`, value: element.shiftId}
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }

  const handleShiftChange = (shift) => {
    setShift(shift)
  }

  const getProduct = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'item/manufacturing_item_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'item/manufacturing_item_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {
              ...element,
              id: element.itemId,
              label: `${element.itemName} ${element.itemCode}`,
              value: element.itemId,
            }
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }

  const onProductChange = (index) => (item) => {
    if(!qcDate) {
      alert('Please select QC Date')
      return
    }
    
    //console.log(item);
    const newQcItems = [...qcItems]
    newQcItems[index].product = item
    newQcItems[index].unit = item.unitName
    setQcItems(newQcItems);
    getProductionQty(item, index);
  }

  const getProductionQty = (item, index) => {
    fetch(`${process.env.REACT_APP_API_URL}qc_operation_production_quantity/${bu}/${item.id}/${qcDate}/1`, getRequestOptions())
      .then((resp) => {
        return resp.json()
      }
      )
      .then((resp) => {
        const newQcItems = [...qcItems]
        newQcItems[index] = {
          ...newQcItems[index],
          productionQty: resp.quantity,
        }
        //console.log(newQcItems);
        setQcItems(newQcItems)
      })
  }

  const onQcPassedQtyChange = (index) => (e) => {
    const newQcItems = [...qcItems]
    newQcItems[index].qcPassedQty = e.target.value
    setQcItems(newQcItems)
  }

  const onQcFailedQtyChange = (index) => (e) => {
    const newQcItems = [...qcItems]
    newQcItems[index].qcFailedQty = e.target.value
    setQcItems(newQcItems)
  }

  const removeQcItem = (index) => {
    const newQcItems = [...qcItems]
    newQcItems.splice(index, 1)
    setQcItems(newQcItems)
  }

  const addQcItem = () => {
    setQcItems(
      qcItems.concat([
        {
          product: '',
          unit: '',
          productionQty: 0,
          qcPassedQty: 0,
          qcFailedQty: 0,
        },
      ])
    )
  }

  const handleSubmit =  (evt) => {
    evt.preventDefault()

    Swal.fire({
      title: 'Are you sure?',
      text: "You want to QC this Cutting!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
    if (result.value) {

    const jwt = () => {
        return JSON.parse(localStorage.getItem('MyToken'))
    }

    const formData = {
      qcDate: qcDate,
      shiftId: shift.value,
      qcItems: qcItems,
      buId: bu,
    }
    //console.log(formData);
    const requestOptions = postRequestOptions(formData);

    fetch(`${process.env.REACT_APP_API_URL}qc/cutting/save`, requestOptions)
    .then((resp) => {
          return resp.json()
    })
    .then((resp) => {
      if (resp.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'QC Data Saved Successfully',
        })
  
        // Clear the form
        setQcDate('');
        setShift('');
        setQcItems([
          {
            product: '',
            unit: '',
            productionQty: 0,
            qcPassedQty: 0,
            qcFailedQty: 0,
          },
        ]);
      } else {
        Swal.fire({
          icon: 'error',
          title: resp.heading,
          html: resp.errorMessage,
        })
      }
    })
    .catch((error) => {
          console.log(error, 'catch the hoop')
    })

  }
  })
  }



  return (
    <>
      <div className='card card-custom mt-5'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              QC Cutting
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='ml-12'>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <label
                  htmlFor='qcDate'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Date <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <input
                    type='date'
                    name='qcDate'
                    id='qcDate'
                    value={qcDate}
                    onChange={(e) => setQcDate(e.target.value)}
                    className='form-control form-control-sm'
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='operationName'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Shift <span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <AsyncSelect
                    value={shift}
                    defaultOptions
                    loadOptions={getShift}
                    onChange={handleShiftChange}
                    getOptionLabel={(option) => (
                      <>
                        <KTSVG
                          path='/media/svg/icons/Code/Time-schedule.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {option.label} </span>
                        <br></br>
                        <KTSVG
                          path='media/svg/icons/Home/Timer.svg'
                          className='svg-icon-2 svg-icon-primary'
                        />
                        <span> {`${option.startTime} - ${option.endTime}`} </span>
                        <br></br>
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table
                    className='table table-bordered table-hover'
                    style={{minWidth: 'max-content'}}
                  >
                    <thead>
                      <tr>
                        <th>SL</th>
                        <th style={{width: '25%'}}>Product</th>
                        <th>Unit</th>
                        <th>Production Qty</th>
                        <th>QC Passed Qty</th>
                        <th>Damage Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {qcItems.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <AsyncSelect
                              value={item.product}
                              defaultOptions
                              loadOptions={getProduct}
                              placeholder='Select Product'
                              onChange={onProductChange(index)}
                              styles={customStylesSelect}
                            />
                          </td>
                          <td>
                            <input type='text' className='form-control form-control-sm' value={item.unit} readOnly />
                          </td>
                          <td>
                            <input
                              type='number'
                              className='form-control form-control-sm'
                              value={item.productionQty}
                              readOnly
                            />
                          </td>
                          <td>
                            <input type='number' className='form-control form-control-sm' value={item.qcPassedQty} onChange={onQcPassedQtyChange(index)} />
                          </td>
                          <td>
                            <input type='number' className='form-control form-control-sm' value={item.qcFailedQty} onChange={onQcFailedQtyChange(index)} />
                          </td>
                          <td>
                            <button
                              type='button'
                              className='btn btn-icon btn-light btn-active-light-danger  btn-sm'
                              onClick={() => removeQcItem(index)}
                            >
                              <span className='svg-icon svg-icon-md svg-icon-danger'>
                                <KTSVG path={'/media/svg/icons/General/Trash.svg'} />
                              </span>
                            </button>
                            {/* delete */}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan='9'>
                          <div className='form-group row'>
                            <div className='col-lg-2'>
                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                onClick={addQcItem}
                              >
                                Add More
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='row'>
                <div className='col-4'></div>
                <div className='col-6'>
                  <input type='submit' className='btn btn-sm btn-success mt-2' value={'Save'} />
                </div>
              </div>
              {/* <pre>{JSON.stringify(wipItems, null, 2)}</pre> */}
            </form>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  )
}

export default QcCutting
